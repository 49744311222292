import { useApolloClient } from '@apollo/client';
import { getFragmentName, subscribe as subscribeBase } from '@chocolate-soup-inc/cs-api-consumer-utils';
import { useEffect } from 'react';
import { onSubscriptionData } from '../../config/apollo/cache';
import {
  DependantFieldsFragmentDoc,
  OnCompanyDependantChangedDocument,
  TDependantType,
  TGetDependantQuery,
  TGetDependantQueryVariables,
  TListCompanyDependantsQuery,
  TListCompanyDependantsQueryVariables,
  TListEmployeeDependantsQuery,
  TListEmployeeDependantsQueryVariables,
  TOnCompanyDependantChangedSubscription,
  TOnCompanyDependantChangedSubscriptionVariables,
  useGetDependantLazyQuery,
  useListCompanyDependantsQuery,
  useListEmployeeDependantsQuery,
} from '../../generated/graphql';
import { useFragmentOrFetch } from '../shared/useFragmentOrFetch';
import { useQueryAll } from '../shared/useQueryAll';

export const getReadableDependantType = (type?: TDependantType) => {
  if (type == null) return undefined;

  switch (type) {
    case TDependantType.Child:
      return 'Child';
    case TDependantType.Pet:
      return 'Pet';
    case TDependantType.SignificantOther:
      return 'Spouse';
  }
};

export const useSubscribeToCompanyDependantChanged = (variables: TOnCompanyDependantChangedSubscriptionVariables) => {
  const client = useApolloClient();

  useEffect(() => {
    subscribeBase({
      client,
      query: OnCompanyDependantChangedDocument,
      variables,
      onSubscriptionData: (data, vars) => {
        // UPDATE QUERIES THAT DEPENDS ONLY ON COMPANY ID
        onSubscriptionData(data, vars);

        const dependantData = (data as TOnCompanyDependantChangedSubscription)?.onCompanyDependantChanged;

        if (dependantData?.employeeId) {
          // UPDATE QUERIES THAT DEPENDS ON EMPLOYEE
          onSubscriptionData(data, {
            employeeId: dependantData.employeeId,
            companyId: variables.companyId,
          });
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(variables)]);
};

export type TGetDependant = Exclude<TGetDependantQuery['getDependant'], undefined | null>;

export const useFragmentOrFetchEmployeeDependant = (variables: TGetDependantQueryVariables) => {
  useSubscribeToCompanyDependantChanged({
    companyId: variables.companyId,
  });

  return useFragmentOrFetch<TGetDependant, TGetDependantQuery, TGetDependantQueryVariables>({
    fragmentDoc: DependantFieldsFragmentDoc,
    fragmentName: getFragmentName(DependantFieldsFragmentDoc),
    useLazyQuery: useGetDependantLazyQuery,
    variables,
    __typename: 'Dependant',
  });
};

export type TGetCompanyDependant = Exclude<
  TListCompanyDependantsQuery['listCompanyDependants']['items'][number],
  undefined | null
>;

export const useQueryAllCompanyDependants = (variables: TListCompanyDependantsQueryVariables) => {
  useSubscribeToCompanyDependantChanged({
    companyId: variables.companyId,
  });

  return useQueryAll<TListCompanyDependantsQuery, TListCompanyDependantsQueryVariables>({
    useQuery: useListCompanyDependantsQuery,
    variables,
  });
};

export const useQueryAllEmployeeDependants = (variables: TListEmployeeDependantsQueryVariables) => {
  useSubscribeToCompanyDependantChanged({
    companyId: variables.companyId,
  });

  return useQueryAll<TListEmployeeDependantsQuery, TListEmployeeDependantsQueryVariables>({
    useQuery: useListEmployeeDependantsQuery,
    variables,
  });
};
