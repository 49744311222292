import { Modal, NavigationTabs } from '@chocolate-soup-inc/cs-frontend-components';
import { ReactNode, useCallback } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { DELIVERIES_PATH, DELIVERY_PATH, DETAILS_PATH, GIFTS_PATH, HISTORY_PATH } from '../../../routes/paths';

import styles from './ShipmentModal.module.scss';

export type TShipmentModalProps = {
  children: ReactNode;
  headline?: string;
};

export const ShipmentModal = (props: TShipmentModalProps) => {
  const { children, headline } = props;
  const navigate = useNavigate();
  const { deliveryId } = useParams();

  const closeModal = useCallback(() => {
    navigate(generatePath(`${DELIVERIES_PATH}/${HISTORY_PATH}`));
  }, [navigate]);

  return (
    <Modal closeModal={closeModal} headline={headline} open={true} size='large'>
      <NavigationTabs
        options={[
          {
            leadingIcon: 'info',
            label: 'Details',
            path: generatePath(`${DELIVERIES_PATH}/${DELIVERY_PATH}/${DETAILS_PATH}`, {
              deliveryId: deliveryId as string,
            }),
          },
          {
            leadingIcon: 'card_giftcard',
            label: 'Gifts',
            path: generatePath(`${DELIVERIES_PATH}/${DELIVERY_PATH}/${GIFTS_PATH}`, {
              deliveryId: deliveryId as string,
            }),
          },
        ]}
      />
      <div className={styles.tabContent}>{children}</div>
    </Modal>
  );
};
