import { ApolloError, SingleExecutionResult } from '@apollo/client';
import {
  ModalForm,
  TControlledFormComponentProps,
  TControlledFormProps,
} from '@chocolate-soup-inc/cs-frontend-components';
import { joiResolver } from '@hookform/resolvers/joi';
import { useCallback, useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { serializeError } from 'serialize-error';
import { getOfficeFormFields } from '../../../entities/office/formFields';
import { getOfficeSchema } from '../../../entities/office/schema';
import { TCreateOfficeMutation, TCreateOfficeMutationInput, useCreateOfficeMutation } from '../../../generated/graphql';
import { usePrivateCompanyContext } from '../../../routes/outlets/PrivateCompanyOutlet';
import { ACCEPT_OFFICE_ADDRESS_PATH, ADMINISTRATION_PATH, OFFICES_PATH } from '../../../routes/paths';

import styles from './OfficeForm.module.scss';
import _ from 'lodash';

export const OfficeCreateForm = () => {
  const navigate = useNavigate();
  const company = usePrivateCompanyContext();

  const closeModal = useCallback(() => {
    navigate(generatePath(`${ADMINISTRATION_PATH}/${OFFICES_PATH}`));
  }, [navigate]);

  const [create] = useCreateOfficeMutation();

  const onSuccess = useCallback(
    (data: SingleExecutionResult<TCreateOfficeMutation>) => {
      if (data.errors) {
        for (const error of data.errors) {
          console.error(serializeError(error));
          toast.error(error.message);
        }
      } else {
        const address = data.data?.createOffice?.address;
        const { address1, address2, city, state, country, zipCode, googleValidated, missingInfo, addressFromGoogle } =
          address || {};

        if (
          data.data?.createOffice?.id &&
          googleValidated &&
          !_.isEmpty(addressFromGoogle) &&
          (address1 !== addressFromGoogle?.address1 ||
            address2 !== addressFromGoogle?.address2 ||
            city !== addressFromGoogle?.city ||
            state !== addressFromGoogle?.state ||
            country !== addressFromGoogle?.country ||
            zipCode !== addressFromGoogle?.zipCode)
        ) {
          // SHOW OPTION TO CHOOSE ADDRESS VALIDATED BY GOOGLE

          navigate(
            generatePath(`${ADMINISTRATION_PATH}/${OFFICES_PATH}/${ACCEPT_OFFICE_ADDRESS_PATH}`, {
              officeId: data.data.createOffice.id,
            }),
          );
        } else {
          if (!_.isEmpty(address) && missingInfo) {
            toast.error(
              'The office was created but the address is missing some required fields. While these fields are not filled, gifts will not be sent to this address.',
            );
          } else if (!_.isEmpty(address) && !googleValidated) {
            toast.warning(
              'The office was successfully created but the address could not be validated by Google. Make sure it is correct otherwise it might be returned when sent.',
            );
          }

          if (data.data?.createOffice?.id) {
            navigate(
              generatePath(`${ADMINISTRATION_PATH}/${OFFICES_PATH}/${ACCEPT_OFFICE_ADDRESS_PATH}`, {
                officeId: data.data.createOffice.id,
              }),
            );
          } else {
            closeModal();
          }
        }
      }
    },
    [closeModal, navigate],
  );

  const onSubmitError = useCallback((error: ApolloError) => {
    if (error instanceof ApolloError) {
      for (const err of error.graphQLErrors) {
        console.error(serializeError(err));
        toast.error(err.message);
      }
    }
  }, []);

  const onSubmit = useCallback<TControlledFormProps<TCreateOfficeMutationInput>['onValidSubmit']>(
    (formData) => {
      if (company?.id) {
        return create({
          variables: {
            input: {
              ...formData,
              companyId: company.id,
            },
          },
        })
          .then(onSuccess)
          .catch(onSubmitError);
      }
    },
    [company, create, onSubmitError, onSuccess],
  );

  const officeFields = useMemo(() => {
    return getOfficeFormFields();
  }, []);

  const officeSchema = useMemo(() => {
    return getOfficeSchema();
  }, []);

  return (
    <ModalForm
      closeModal={closeModal}
      controlledFormProps={{
        className: styles.form,
        fields: officeFields as TControlledFormComponentProps<TCreateOfficeMutationInput>[],
        formProps: {
          resolver: joiResolver(officeSchema, {
            convert: true,
            abortEarly: false,
            stripUnknown: false,
          }),
        },
        onValidSubmit: onSubmit,
      }}
      headline='Create Office'
    />
  );
};
