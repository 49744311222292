import Joi from 'joi';
import { TPrivateCompanyFieldsFragment, TUserType } from '../../generated/graphql';
import { clearableField } from '../shared/clearableField';

type TGetUserSchemaProps = {
  company?: TPrivateCompanyFieldsFragment;
};

export const getUserSchema = (props: TGetUserSchemaProps) => {
  const { company: { emailDomains } = {} } = props;

  const schema = Joi.object()
    .unknown(false)
    .keys({
      name: Joi.string().trim().empty(Joi.valid('', null)).required(),
      email: Joi.string()
        .trim()
        .lowercase()
        .email({
          tlds: {
            allow: false,
          },
        })
        .empty(Joi.valid('', null))
        .required()
        .custom((value) => {
          if (!emailDomains?.includes(value.split('@')[1])) {
            throw new Error(`Email from invalid domain. It needs to be one of [${emailDomains?.join(', ')}]`);
          }

          return value;
        }),
      title: clearableField(Joi.string().trim()),
      type: Joi.string()
        .valid(...Object.values(TUserType))
        .required(),
    });

  return schema;
};
