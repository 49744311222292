import { ApolloError, SingleExecutionResult } from '@apollo/client';
import { ModalForm, TControlledFormProps } from '@chocolate-soup-inc/cs-frontend-components';
import { joiResolver } from '@hookform/resolvers/joi';
import { useCallback, useMemo } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { getEmployeeDependantSchema } from '../../../entities/dependant/schema';
import {
  TCreateDependantMutation,
  TCreateDependantMutationInput,
  useCreateDependantMutation,
} from '../../../generated/graphql';
import styles from './EmployeeDependantForm.module.scss';
import { DEPENDANTS_PATH, EMPLOYEES_PATH, EMPLOYEE_PATH } from '../../../routes/paths';
import { getEmployeeDependantFormFields } from '../../../entities/dependant/formFields';
import { usePrivateCompanyContext } from '../../../routes/outlets/PrivateCompanyOutlet';
import { toast } from 'react-toastify';
import { serializeError } from 'serialize-error';

export const EmployeeDependantCreateForm = () => {
  const navigate = useNavigate();
  const { employeeId } = useParams();

  const company = usePrivateCompanyContext();
  const companyId = useMemo(() => company.id, [company.id]);

  const closeModal = useCallback(() => {
    navigate(generatePath(EMPLOYEES_PATH));
  }, [navigate]);

  const onBackClick = useCallback(() => {
    navigate(
      generatePath(`${EMPLOYEES_PATH}/${EMPLOYEE_PATH}/${DEPENDANTS_PATH}`, {
        employeeId: employeeId as string,
      }),
    );
  }, [employeeId, navigate]);

  const [create] = useCreateDependantMutation();

  const onSuccess = useCallback(
    (data: SingleExecutionResult<TCreateDependantMutation>) => {
      if (data.errors) {
        for (const error of data.errors) {
          console.error(serializeError(error));
          toast.error(error.message);
        }
      } else {
        navigate(
          generatePath(`${EMPLOYEES_PATH}/${EMPLOYEE_PATH}/${DEPENDANTS_PATH}`, {
            employeeId: data.data?.createDependant?.employeeId as string,
          }),
        );
      }
    },
    [navigate],
  );

  const onSubmitError = useCallback((error: ApolloError) => {
    if (error instanceof ApolloError) {
      for (const err of error.graphQLErrors) {
        console.error(serializeError(err));
        toast.error(err.message);
      }
    }
  }, []);

  const onSubmit = useCallback<TControlledFormProps<TCreateDependantMutationInput>['onValidSubmit']>(
    (formData) => {
      if (employeeId) {
        return create({
          variables: {
            input: {
              ...formData,
              employeeId,
              companyId,
            },
          },
        })
          .then(onSuccess)
          .catch(onSubmitError);
      }
    },
    [companyId, create, employeeId, onSubmitError, onSuccess],
  );

  return (
    <ModalForm
      onBackClick={onBackClick}
      closeModal={closeModal}
      controlledFormProps={{
        className: styles.form,
        fields: getEmployeeDependantFormFields({ company, styles }),
        formProps: {
          resolver: joiResolver(getEmployeeDependantSchema(), {
            convert: true,
            abortEarly: false,
            stripUnknown: false,
          }),
        },
        onValidSubmit: onSubmit,
      }}
      headline='Create Dependant'
    />
  );
};
