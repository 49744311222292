import { TControlledFormComponentProps } from '@chocolate-soup-inc/cs-frontend-components';
import { TDependantType, TPrivateCompanyFieldsFragment } from '../../generated/graphql';

type TGetEmployeeDependantFormFieldsProps = {
  company?: TPrivateCompanyFieldsFragment;
  styles?: Record<string, any>;
};

export const getEmployeeDependantFormFields = (props?: TGetEmployeeDependantFormFieldsProps) => {
  const { company, styles } = props || {};

  const relationShipOptions = [];

  if (company?.significantOtherBirthdayActivated) {
    relationShipOptions.push({
      value: TDependantType.SignificantOther,
      label: 'Significant Other',
    });
  }

  if (company?.childBirthdayActivated) {
    relationShipOptions.push({
      value: TDependantType.Child,
      label: 'Child',
    });
  }

  if (company?.petBirthdayActivated) {
    relationShipOptions.push({
      value: TDependantType.Pet,
      label: 'Pet',
    });
  }

  const formFields: TControlledFormComponentProps[] = [
    {
      name: 'type',
      type: 'select',
      label: 'Relationship Type',
      inputOptions: {
        multiple: false,
        autoComplete: 'off',
        options: relationShipOptions,
        variant: 'outlined',
      },
    },
    {
      name: 'petType',
      label: 'Pet Type',
      type: 'textField',
      show: (data) => data.type === TDependantType.Pet,
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      name: 'firstName',
      label: 'First Name',
      type: 'textField',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      name: 'lastName',
      label: 'Last Name',
      type: 'textField',
      show: (data) => data.type !== TDependantType.Pet,
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      name: 'birthDate',
      label: 'Birth Date',
      type: 'datePicker',
      inputOptions: {
        clearDisabled: false,
      },
    },
    {
      className: 'col-span-2',
      type: 'switch',
      label: 'Donate birthday gift to charity',
      name: 'donateBirthdayGift',
      show: (data) => data.type === TDependantType.SignificantOther,
    },
    {
      className: styles?.foodPreferences,
      name: 'foodPreferences',
      label: 'Allergies & Dietary Restrictions',
      type: 'textArea',
      inputOptions: {
        multiline: true,
        autoComplete: 'off',
      },
    },
  ];
  return formFields;
};
