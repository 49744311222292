import { ApolloClient, useApolloClient } from '@apollo/client';
import { subscribe as subscribeBase, TConnectionItem } from '@chocolate-soup-inc/cs-api-consumer-utils';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { serializeError } from 'serialize-error';
import { onSubscriptionData } from '../../config/apollo/cache';
import {
  GetEventDocument,
  OnCompanyEventChangedDocument,
  TGetEventQuery,
  TGetEventQueryVariables,
  TListCompanyUpcomingEventsQuery,
  TListCompanyUpcomingEventsQueryVariables,
  TOnCompanyEventChangedSubscription,
  TOnCompanyEventChangedSubscriptionVariables,
  useListCompanyUpcomingEventsQuery,
} from '../../generated/graphql';
import { useQueryAll } from '../shared/useQueryAll';

type TOnEventSubscriptionDataProps = {
  client: ApolloClient<object>;
  data?: TOnCompanyEventChangedSubscription;
  vars?: TOnCompanyEventChangedSubscriptionVariables;
};

const onEventSubscriptionData = (props: TOnEventSubscriptionDataProps) => {
  const { client, data, vars } = props;

  const { companyId, id, _deleted } = data?.onCompanyEventChanged || {};

  if (_deleted) {
    return onSubscriptionData(data as Record<string, any>, vars);
  } else if (companyId && id) {
    client
      .query<TGetEventQuery, TGetEventQueryVariables>({
        query: GetEventDocument,
        variables: {
          id,
          companyId,
        },
      })
      .then((response) => {
        const eventData = response?.data.getEvent as TConnectionItem;

        if (eventData) {
          const subscriptionData = {
            onCompanyGiftChanged: eventData,
          };

          // UPDATE QUERIES THAT DO NOT DEPEND ONLY ON COMPANYID
          onSubscriptionData(subscriptionData, {
            companyId,
          });
        }
      })
      .catch((error) => {
        console.error(serializeError(error));
      });
  }
};

export const useSubscribeToCompanyEventChangedSubscription = (
  variables: TOnCompanyEventChangedSubscriptionVariables,
) => {
  const client = useApolloClient();

  useEffect(() => {
    subscribeBase({
      client,
      query: OnCompanyEventChangedDocument,
      variables,
      onSubscriptionData: (data, vars) => {
        onEventSubscriptionData({
          client,
          data: data as TOnCompanyEventChangedSubscription,
          vars: vars as TOnCompanyEventChangedSubscriptionVariables,
        });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, JSON.stringify(variables)]);
};

export const useQueryAllCompanyUpcomingEvents = (variables: TListCompanyUpcomingEventsQueryVariables) => {
  useSubscribeToCompanyEventChangedSubscription({
    companyId: variables.companyId,
  });

  const { data, error, loading } = useQueryAll<
    TListCompanyUpcomingEventsQuery,
    TListCompanyUpcomingEventsQueryVariables
  >({
    useQuery: useListCompanyUpcomingEventsQuery,
    variables,
  });

  const upcomingEvents = useMemo(() => {
    return _.compact(data?.listCompanyUpcomingEvents.items || []);
  }, [data?.listCompanyUpcomingEvents.items]);

  return {
    data: upcomingEvents,
    error,
    loading,
  };
};
