import { AuthOptions, AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { ApolloClient, HttpLink, ApolloLink } from '@apollo/client';
import { Auth } from '@chocolate-soup-inc/cs-api-consumer-utils';
import { cache } from './cache';
import { onError } from '@apollo/client/link/error';
import { generatePath } from 'react-router-dom';
import { LOGIN_PATH } from '../../routes/paths';

const url = process.env.REACT_APP_API_URL as string;
const region = process.env.REACT_APP_REGION as string;

const ErrorLink = onError((e: any) => {
  if (e?.networkError?.statusCode === 401 || e?.networkError?.statusCode === 403) {
    localStorage.clear();
    window.location.href = generatePath(LOGIN_PATH);
  }
});

const publicAuthOptions: AuthOptions = {
  type: AUTH_TYPE.API_KEY,
  apiKey: process.env.REACT_APP_API_KEY as string,
};

const privateAuthOptions: AuthOptions = {
  type: AUTH_TYPE.AWS_LAMBDA,
  token: () => {
    return Auth.currentSession().then((session) => {
      return session.getAccessToken().getJwtToken();
    });
  },
};

const httpLink = new HttpLink({ uri: url });

const publicLink = ApolloLink.from([
  createAuthLink({
    url,
    region,
    auth: publicAuthOptions,
  }),
  httpLink,
]);

const privateLink = ApolloLink.from([
  ErrorLink,
  createAuthLink({
    url,
    region,
    auth: privateAuthOptions,
  }),
  createSubscriptionHandshakeLink(
    {
      url,
      region,
      auth: privateAuthOptions,
    },
    httpLink,
  ),
]);

export const publicClient = new ApolloClient({
  link: publicLink,
  cache,
  connectToDevTools: true,
  name: 'public',
});

export const privateClient = new ApolloClient({
  link: privateLink,
  cache,
  connectToDevTools: true,
  name: 'private',
});
