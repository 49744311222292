import { useMemo } from 'react';
import { getEmployeeFormFields } from '../../../entities/employee/formFields';
import { getEmployeeSchema } from '../../../entities/employee/schema';
import { useQueryAllOffices } from '../../../entities/office/shared';
import { usePrivateCompanyContext } from '../../../routes/outlets/PrivateCompanyOutlet';

export const useEmployeeForm = () => {
  const company = usePrivateCompanyContext();

  const { data, loading, error } = useQueryAllOffices({
    companyId: company.id,
  });

  const offices = useMemo(() => {
    return data?.listOffices?.items;
  }, [data?.listOffices?.items]);

  const employeeFormFields = useMemo(() => {
    return getEmployeeFormFields({
      company,
      offices,
    });
  }, [company, offices]);

  const employeeSchema = useMemo(() => {
    return getEmployeeSchema({
      company,
      offices,
    });
  }, [company, offices]);

  return {
    employeeFormFields,
    employeeSchema,
    loading: data == null || loading,
    error,
  };
};
