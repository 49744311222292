import { useMemo } from 'react';
import { generatePath, useParams } from 'react-router-dom';

import {
  DEPENDANTS_PATH,
  EMPLOYEES_PATH,
  EMPLOYEE_ACCOUNT_PATH,
  EMPLOYEE_PATH,
  GIFTS_PATH,
} from '../../../routes/paths';

import { NavigationTabs, TNavigationTabLinkProps } from '@chocolate-soup-inc/cs-frontend-components';
import { usePrivateCompanyContext } from '../../../routes/outlets/PrivateCompanyOutlet';

import styles from './EmployeeTabs.module.scss';

export const EmployeeTabs = () => {
  const { employeeId } = useParams();
  const { petBirthdayActivated, childBirthdayActivated, significantOtherBirthdayActivated } =
    usePrivateCompanyContext();

  // const context = useRouteModalContext();
  // const { setModalProps } = context;

  // useEffect(() => {
  //   setModalProps({
  //     contentClassName: styles.modal,
  //   });
  // }, [setModalProps]);

  const options: TNavigationTabLinkProps[] = useMemo(() => {
    const opts: TNavigationTabLinkProps[] = [];

    if (employeeId) {
      opts.push({
        label: 'Account',
        path: generatePath(`${EMPLOYEES_PATH}/${EMPLOYEE_PATH}/${EMPLOYEE_ACCOUNT_PATH}`, {
          employeeId,
        }),
      });

      if (petBirthdayActivated || childBirthdayActivated || significantOtherBirthdayActivated) {
        opts.push({
          label: 'Dependants',
          path: generatePath(`${EMPLOYEES_PATH}/${EMPLOYEE_PATH}/${DEPENDANTS_PATH}`, {
            employeeId,
          }),
        });
      }

      opts.push({
        label: 'Gifts',
        path: generatePath(`${EMPLOYEES_PATH}/${EMPLOYEE_PATH}/${GIFTS_PATH}`, {
          employeeId,
        }),
      });
    }

    return opts;
  }, [employeeId, petBirthdayActivated, childBirthdayActivated, significantOtherBirthdayActivated]);

  return (
    <div className={styles.wrapper}>
      <NavigationTabs
        hideOutlet={true}
        className={styles.tabs}
        contentClassName={styles.content}
        options={options}
        // outletContext={context}
      />
    </div>
  );
};
