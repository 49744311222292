import { ApolloClient, useApolloClient } from '@apollo/client';
import {
  getFragmentName,
  subscribe as subscribeBase,
  TConnectionItem,
} from '@chocolate-soup-inc/cs-api-consumer-utils';
import { useEffect } from 'react';
import { serializeError } from 'serialize-error';
import { onSubscriptionData } from '../../config/apollo/cache';
import {
  EmployeeFieldsFragmentDoc,
  GetEmployeeDocument,
  OnCompanyEmployeeChangedDocument,
  TGetEmployeeQuery,
  TGetEmployeeQueryVariables,
  TListCompanyEmployeesQuery,
  TListCompanyEmployeesQueryVariables,
  TOnCompanyEmployeeChangedSubscription,
  TOnCompanyEmployeeChangedSubscriptionVariables,
  useGetEmployeeLazyQuery,
  useListCompanyEmployeesQuery,
} from '../../generated/graphql';
import { useFragmentOrFetch } from '../shared/useFragmentOrFetch';
import { useQueryAll } from '../shared/useQueryAll';

type TOnEmployeeSubscriptionDataProps = {
  client: ApolloClient<object>;
  data?: TOnCompanyEmployeeChangedSubscription;
  vars?: TOnCompanyEmployeeChangedSubscriptionVariables;
};

const onEmployeeSubscriptionData = (props: TOnEmployeeSubscriptionDataProps) => {
  const { client, data, vars } = props;

  const { companyId, id, _deleted } = data?.onCompanyEmployeeChanged || {};
  if (_deleted) {
    return onSubscriptionData(data as Record<string, any>, vars);
  } else if (companyId && id) {
    client
      .query<TGetEmployeeQuery, TGetEmployeeQueryVariables>({
        query: GetEmployeeDocument,
        variables: {
          id,
          companyId,
        },
      })
      .then((response) => {
        const employeeData = response?.data?.getEmployee as TConnectionItem;

        if (employeeData) {
          return onSubscriptionData(
            {
              onCompanyEmployeeChanged: employeeData,
            },
            vars,
          );
        }
      })
      .catch((error) => {
        console.error(serializeError(error));
      });
  }
};

export const useSubscribeToCompanyEmployeeChanged = (variables: TOnCompanyEmployeeChangedSubscriptionVariables) => {
  const client = useApolloClient();

  useEffect(() => {
    subscribeBase({
      client,
      query: OnCompanyEmployeeChangedDocument,
      variables,
      onSubscriptionData(data, vars) {
        onEmployeeSubscriptionData({
          client,
          data: data as TOnCompanyEmployeeChangedSubscription,
          vars: vars as TOnCompanyEmployeeChangedSubscriptionVariables,
        });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, JSON.stringify(variables)]);
};

export type TGetEmployee = Exclude<TGetEmployeeQuery['getEmployee'], undefined | null>;

export const useFragmentOrFetchEmployee = (variables: TGetEmployeeQueryVariables) => {
  useSubscribeToCompanyEmployeeChanged({
    companyId: variables.companyId,
  });

  return useFragmentOrFetch<TGetEmployee, TGetEmployeeQuery, TGetEmployeeQueryVariables>({
    fragmentDoc: EmployeeFieldsFragmentDoc,
    fragmentName: getFragmentName(EmployeeFieldsFragmentDoc),
    useLazyQuery: useGetEmployeeLazyQuery,
    variables,
    __typename: 'Employee',
  });
};

export type TListEmployee = Exclude<
  TListCompanyEmployeesQuery['listCompanyEmployees']['items'][number],
  undefined | null
>;

export const useQueryAllEmployees = (variables: TListCompanyEmployeesQueryVariables) => {
  useSubscribeToCompanyEmployeeChanged({
    companyId: variables.companyId,
  });

  return useQueryAll<TListCompanyEmployeesQuery, TListCompanyEmployeesQueryVariables>({
    useQuery: useListCompanyEmployeesQuery,
    variables,
  });
};
