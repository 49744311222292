import { Auth } from '@chocolate-soup-inc/cs-api-consumer-utils';
import { CommonButton, ControlledTextField, ErrorPage } from '@chocolate-soup-inc/cs-frontend-components';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LOGIN_PATH } from '../../../routes/paths';
import { AuthPage } from '../shared/AuthPage';
import styles from '../shared/AuthPage.module.scss';
import { PasswordRequirement } from '../shared/PasswordRequirement';
import { JOI_PASSWORD_RULES } from '../shared/utils';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';

type TFormFields = {
  password: string;
  passwordConfirmation: string;
};

export const ForgotPasswordNewPasswordPage = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const { control, handleSubmit, formState, watch } = useForm<TFormFields>({
    resolver: joiResolver(Joi.object(JOI_PASSWORD_RULES)),
  });

  const { password, passwordConfirmation } = watch();

  const userEmail = useMemo(() => {
    return searchParams.get('userEmail');
  }, [searchParams]);

  const userCode = useMemo(() => {
    return searchParams.get('userCode');
  }, [searchParams]);

  const onSubmit = useCallback(
    async (data: TFormFields) => {
      if (userEmail != null && userCode != null) {
        try {
          await Auth.forgotPasswordSubmit(userEmail, userCode, data.password);
          await Auth.signIn(userEmail, data.password);
        } catch (error: any) {
          toast.error(error?.message || 'There was an error trying to set the new password.');
        }
      }
    },
    [userCode, userEmail],
  );

  const navigateToLogin = useCallback(() => {
    navigate(generatePath(LOGIN_PATH));
  }, [navigate]);

  if (userCode == null || userEmail == null) return <ErrorPage message='Bad link.' />;

  return (
    <AuthPage title='New password'>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <ControlledTextField
          control={control}
          label='Password'
          multiline={false}
          name='password'
          variant='outlined'
          type='password'
        />
        <ControlledTextField
          control={control}
          label='Password Confirmation'
          multiline={false}
          name='passwordConfirmation'
          variant='outlined'
          type='password'
        />
        <PasswordRequirement password={password} passwordConfirmation={passwordConfirmation} />
        <CommonButton
          className={styles.formButton}
          label='Update password'
          loading={formState.isSubmitting}
          type='submit'
        />
        <CommonButton label='Go to Login' onClick={navigateToLogin} variant='text' />
      </form>
    </AuthPage>
  );
};
