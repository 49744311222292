import { ApolloError, SingleExecutionResult } from '@apollo/client';
import {
  ErrorPage,
  LoadingPage,
  ModalForm,
  TControlledFormComponentProps,
  TControlledFormProps,
} from '@chocolate-soup-inc/cs-frontend-components';
import { joiResolver } from '@hookform/resolvers/joi';
import { useCallback, useMemo } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { serializeError } from 'serialize-error';
import { getOfficeFormFields } from '../../../entities/office/formFields';
import { getOfficeDefaultValues, getOfficeSchema } from '../../../entities/office/schema';
import { useFragmentOrFetchOffice } from '../../../entities/office/shared';
import { TUpdateOfficeMutation, TUpdateOfficeMutationInput, useUpdateOfficeMutation } from '../../../generated/graphql';
import { usePrivateCompanyContext } from '../../../routes/outlets/PrivateCompanyOutlet';
import { ACCEPT_OFFICE_ADDRESS_PATH, ADMINISTRATION_PATH, OFFICES_PATH } from '../../../routes/paths';

import styles from './OfficeForm.module.scss';
import _ from 'lodash';

export const OfficeUpdateForm = () => {
  const navigate = useNavigate();
  const { officeId } = useParams();
  const company = usePrivateCompanyContext();

  const closeModal = useCallback(() => {
    navigate(generatePath(`${ADMINISTRATION_PATH}/${OFFICES_PATH}`));
  }, [navigate]);

  const {
    data: office,
    error: getOfficeError,
    loading: getOfficeLoading,
  } = useFragmentOrFetchOffice({
    id: officeId as string,
    companyId: company.id,
  });

  const title = useMemo(() => {
    if (office?.name) {
      return `Update ${office.name}`;
    } else {
      return 'Update Office';
    }
  }, [office?.name]);

  const [update] = useUpdateOfficeMutation();

  const onSuccess = useCallback(
    (data: SingleExecutionResult<TUpdateOfficeMutation>) => {
      if (data.errors) {
        for (const error of data.errors) {
          console.error(serializeError(error));
          toast.error(error.message);
        }
      } else {
        const address = data.data?.updateOffice?.address;

        const {
          alert: addressAlert,
          address1,
          address2,
          city,
          state,
          country,
          zipCode,
          googleValidated,
          missingInfo,
          addressFromGoogle,
        } = address || {};

        if (
          data.data?.updateOffice?.id &&
          googleValidated &&
          !_.isEmpty(addressFromGoogle) &&
          (address1 !== addressFromGoogle?.address1 ||
            address2 !== addressFromGoogle?.address2 ||
            city !== addressFromGoogle?.city ||
            state !== addressFromGoogle?.state ||
            country !== addressFromGoogle?.country ||
            zipCode !== addressFromGoogle?.zipCode)
        ) {
          // SHOW OPTION TO CHOOSE ADDRESS VALIDATED BY GOOGLE
          navigate(
            generatePath(`${ADMINISTRATION_PATH}/${OFFICES_PATH}/${ACCEPT_OFFICE_ADDRESS_PATH}`, {
              officeId: data.data.updateOffice?.id,
            }),
          );
        } else {
          if (!_.isEmpty(address) && missingInfo) {
            toast.error(
              'The office was updated but the address is missing some required fields. While these fields are not filled, gifts will not be sent to this address.',
            );
          } else if (!_.isEmpty(address) && !googleValidated && addressAlert) {
            toast.warning(
              'The office was successfully updated but the address could not be validated by Google. Make sure it is correct otherwise it might be returned when sent.',
            );
          }
          closeModal();
        }
      }
    },
    [navigate, closeModal],
  );

  const onSubmitError = useCallback((error: ApolloError) => {
    if (error instanceof ApolloError) {
      for (const err of error.graphQLErrors) {
        console.error(serializeError(err));
        toast.error(err.message);
      }
    }
  }, []);

  const onSubmit = useCallback<TControlledFormProps<TUpdateOfficeMutationInput>['onValidSubmit']>(
    (formData) => {
      if (officeId) {
        if (
          _.isEmpty(formData.contact?.name) &&
          _.isEmpty(formData.contact?.email) &&
          _.isEmpty(formData.contact?.phoneNumber)
        ) {
          formData.contact = null;
        }

        return update({
          variables: {
            id: officeId,
            companyId: company.id,
            version: office?._version as number,
            input: {
              ...formData,
            },
          },
        })
          .then(onSuccess)
          .catch(onSubmitError);
      }
    },
    [company.id, office?._version, officeId, onSubmitError, onSuccess, update],
  );

  const officeFields = useMemo(() => {
    return getOfficeFormFields();
  }, []);

  const officeSchema = useMemo(() => {
    return getOfficeSchema();
  }, []);

  if (getOfficeError) return <ErrorPage error={getOfficeError} />;
  if (getOfficeLoading) return <LoadingPage />;

  return (
    <ModalForm
      closeModal={closeModal}
      controlledFormProps={{
        className: styles.form,
        fields: officeFields as TControlledFormComponentProps<TUpdateOfficeMutationInput>[],
        formProps: {
          defaultValues: getOfficeDefaultValues(office),
          resolver: joiResolver(officeSchema, {
            convert: true,
            abortEarly: false,
            stripUnknown: false,
          }),
        },
        onValidSubmit: onSubmit,
      }}
      headline={title}
    />
  );
};
