import clsx from 'clsx';
import { Dispatch, SetStateAction, useState } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import Header from './Header';
import styles from './Layout.module.scss';
import { Sidebar } from './Sidebar';

const Layout = () => {
  const [contentClassName, setContentClassName] = useState<string>();

  return (
    <div className={styles.layout}>
      <div className={styles.header}>
        <Header />
      </div>
      <div className={styles.sidebar}>
        <Sidebar />
      </div>
      <div className={clsx(styles.content, contentClassName)}>
        <Outlet
          context={{
            className: styles.contentOutlet,
            fullGridClassName: styles.fullGrid,
            gridContainerClassName: styles.gridContainer,
            titleClassName: styles.titleOutlet,
            setContentClassName,
          }}
        />
      </div>
    </div>
  );
};

export const useLayoutContext = () => {
  return useOutletContext<{
    className: string;
    fullGridClassName: string;
    gridContainerClassName: string;
    titleClassName: string;
    setContentClassName: Dispatch<SetStateAction<string | undefined>>;
    setGridClassName: Dispatch<SetStateAction<string | undefined>>;
  }>();
};

export default Layout;
