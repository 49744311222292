import {
  Card,
  Chip,
  ErrorPage,
  getDateWithTimezoneFixed,
  Icon,
  LoadingPage,
  readableDate,
  TableInner,
  TMaterialIcons,
  Tooltip,
} from '@chocolate-soup-inc/cs-frontend-components';
import clsx from 'clsx';
import format from 'date-fns/format';
import { useCallback, useMemo, useRef } from 'react';
import { useQueryAllCompanyUpcomingEvents } from '../../../entities/events/shared';
import { TListShipment, useQueryAllShipments } from '../../../entities/shipment/shared';
import { isDependant } from '../../../entities/recipient/shared';
import { TEventTypes, TShipmentStatuses } from '../../../generated/graphql';
import { usePrivateCompanyContext } from '../../../routes/outlets/PrivateCompanyOutlet';
import { useLayoutContext } from '../../shared/Layout';
import { useVirtualizer } from '@tanstack/react-virtual';

import styles from './Overview.module.scss';
import { CellContext } from '@tanstack/react-table';
import { GiftsCell, RecipientCell, StatusCell, TrackingLink } from '../Deliveries/Deliveries';
import { EmployeesMissingInfoAlert } from '../../employees/EmployeeManagement/EmployeesMissingInfoAlert';
import { EmployeesWithAddressAlert } from '../../employees/EmployeeManagement/EmployeesWithAddressAlert';
import { getReadableDependantType } from '../../../entities/dependant/shared';
import { generatePath, useNavigate } from 'react-router-dom';
import { DELIVERIES_PATH, HISTORY_PATH } from '../../../routes/paths';
import tableStyles from '../../../components/TablePage/TablePage.module.scss';

const getEventIcon = (type: TEventTypes): TMaterialIcons => {
  switch (type) {
    case TEventTypes.Birthday:
      return 'cake';
    case TEventTypes.WorkAnniversary:
      return 'celebration';
    case TEventTypes.NewHire:
      return 'rocket_launch';
    default:
      return 'card_giftcard';
  }
};

const getEventType = (type: TEventTypes) => {
  switch (type) {
    case TEventTypes.Birthday:
      return 'Birthday';
    case TEventTypes.WorkAnniversary:
      return 'Work Anniversary';
    case TEventTypes.NewHire:
      return 'New Hire';
    default:
      return 'One Off';
  }
};

type TDeliveryOverviewBoxProps = {
  icon: TMaterialIcons;
  loading?: boolean;
  shipments: TListShipment[];
  title: string;
};

export const DeliveryOverviewBox = (props: TDeliveryOverviewBoxProps) => {
  const { icon, loading, shipments, title } = props;
  const navigate = useNavigate();

  const navigateToFilteredDelivered = useCallback(() => {
    navigate(generatePath(`${DELIVERIES_PATH}/${HISTORY_PATH}`), {
      state: title,
    });
  }, [navigate, title]);

  return (
    <Card className={styles.deliveryOverviewBox} readOnly={loading} variant='outlined'>
      {loading && <LoadingPage />}
      {!loading && (
        <button
          className={styles.deliveryOverviewBoxButton}
          onClick={navigateToFilteredDelivered}
          disabled={!shipments.length}
        >
          <Icon className={styles.deliveryOverviewBoxIcon} icon={icon} />
          <span className={styles.deliveryOverviewBoxNumber}>{shipments.length}</span>
          <span className={styles.deliveryOverviewBoxTitle}>{title}</span>
        </button>
      )}
    </Card>
  );
};

export const Overview = () => {
  const { className, titleClassName } = useLayoutContext();
  const { id: companyId } = usePrivateCompanyContext();

  const {
    data: shipments,
    error: shipmentsError,
    loading: shipmentsLoading,
  } = useQueryAllShipments({
    companyId,
  });

  const {
    data: unsortedUpcomingEvents,
    error: eventsError,
    loading: eventsLoading,
  } = useQueryAllCompanyUpcomingEvents({
    companyId,
  });

  const upcomingEvents = useMemo(() => {
    return unsortedUpcomingEvents.sort((a, b) => {
      return new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime();
    });
  }, [unsortedUpcomingEvents]);

  const readyToShipShipments = useMemo(() => {
    return shipments.filter((s) => {
      return [TShipmentStatuses.Packaging, TShipmentStatuses.ReadyToShip].includes(s.status);
    });
  }, [shipments]);

  const inTransitShipments = useMemo(() => {
    return shipments.filter((s) => {
      return [TShipmentStatuses.PrintingLabel, TShipmentStatuses.LabelPrinted, TShipmentStatuses.Shipped].includes(
        s.status,
      );
    });
  }, [shipments]);

  const needsAttentionShipments = useMemo(() => {
    return shipments.filter((s) => {
      [TShipmentStatuses.Attention].includes(s.status);
    });
  }, [shipments]);

  const eventsScrollContainer = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer<HTMLDivElement, HTMLElement>({
    estimateSize: () => 70,
    getScrollElement: () => eventsScrollContainer.current,
    count: upcomingEvents.length,
    overscan: 5,
  });

  const totalSize = rowVirtualizer.getTotalSize();
  const virtualRows = rowVirtualizer.getVirtualItems();

  const padding = useMemo(() => {
    return {
      top: virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0,
      bottom: virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0,
    };
  }, [totalSize, virtualRows]);

  const getRowId = useCallback((shipment: TListShipment) => {
    return shipment.id;
  }, []);

  if (shipmentsError || eventsError) return <ErrorPage error={shipmentsError || eventsError} />;

  return (
    <>
      <EmployeesMissingInfoAlert className={styles.overviewPageEmployeesError} backTo={window.location.pathname} />
      <EmployeesWithAddressAlert className={styles.overviewPageEmployeesAlert} backTo={window.location.pathname} />
      <div className={clsx(className, styles.overviewPage)}>
        <h1 className={clsx(titleClassName, styles.overviewPageTitle)}>Overview</h1>
        <div className={styles.overviewBoxesContainer}>
          <DeliveryOverviewBox
            icon='inventory'
            loading={shipmentsLoading}
            shipments={readyToShipShipments}
            title='Ready to Ship'
          />
          <DeliveryOverviewBox
            icon='local_shipping'
            loading={shipmentsLoading}
            shipments={inTransitShipments}
            title='In Transit'
          />
          <DeliveryOverviewBox
            icon='warning'
            loading={shipmentsLoading}
            shipments={needsAttentionShipments}
            title='Needs Attention'
          />
        </div>
        <div className={styles.overviewPageAttentionList}>
          <h2 className={styles.overviewPageAttentionTitle}>Needs attention list</h2>
          <TableInner<TListShipment>
            className={styles.overviewPageAttentionListTable}
            data={needsAttentionShipments}
            emptyText='Yippee, nothing needing attention here!'
            fixedHeader={true}
            getRowId={getRowId}
            virtual={false}
            columns={[
              {
                header: 'Tracking Number',
                cell: TrackingLink,
              },
              {
                header: 'Shipping Date',
                cell: ({ cell }: CellContext<TListShipment, unknown>) => {
                  const { actualShippingDate, shippingDate } = cell.row.original;
                  return (
                    <div className={clsx(tableStyles.tableSpaceSecondary)}>
                      <span>{readableDate(new Date(actualShippingDate || shippingDate))}</span>
                    </div>
                  );
                },
              },
              {
                header: 'Recipient',
                cell: RecipientCell,
              },
              {
                header: 'Gifts',
                cell: GiftsCell,
              },
              {
                header: 'Status',
                cell: StatusCell,
              },
            ]}
          />
        </div>
        <Card className={styles.overviewPageEventList} readOnly={true} variant='outlined'>
          <h2 className={styles.overviewPageEventTitle}>
            Upcoming Events ({`${upcomingEvents.length} ${upcomingEvents.length == 1 ? 'event' : 'events'}`})
          </h2>
          {eventsLoading && <LoadingPage />}
          {!eventsLoading && (
            <div className={styles.overviewPageEventListInner} ref={eventsScrollContainer}>
              {padding.top > 0 && <div style={{ height: `${padding.top}px`, width: '100%' }} />}
              {virtualRows.length === 0 && <span className={styles.emptyUpcomingEvents}>No upcoming events.</span>}
              {virtualRows.length > 0 &&
                virtualRows.map((row) => {
                  const event = upcomingEvents[row.index];

                  const { recipient } = event;

                  return (
                    <Card
                      className={clsx(styles.overviewPageEvent, event.isPaused && styles.paused)}
                      key={row.key}
                      readOnly={true}
                      variant='outlined'
                    >
                      <Tooltip message={getEventType(event.type)}>
                        <Icon className={styles.eventIcon} icon={getEventIcon(event.type)} />
                      </Tooltip>
                      <span className={styles.eventRecipient}>{`${recipient?.fullName}${
                        recipient != null && isDependant(recipient)
                          ? ` (${getReadableDependantType(recipient.type)} of ${recipient.employee?.fullName})`
                          : ''
                      }`}</span>
                      <span className={styles.eventType}>
                        {format(getDateWithTimezoneFixed(event.eventDate) as Date, 'dd/MM/yyyy')} -{' '}
                        {getEventType(event.type)}
                      </span>
                      {event.isPaused && (
                        <Chip
                          className={styles.pausedChip}
                          hideSelectedIcon={true}
                          label='Paused'
                          leadingIcon='pause_circle'
                          readonly={true}
                          selected={true}
                          variant='suggestion'
                        />
                      )}
                    </Card>
                  );
                })}
              {padding.bottom > 0 && <div style={{ height: `${padding.bottom}px`, width: '100%' }} />}
            </div>
          )}
        </Card>
      </div>
    </>
  );
};
