import styles from '../shared/AuthPage.module.scss';
import { usePublicCompanyContext } from '../../../routes/outlets/PublicCompanyOutlet';
import { Auth } from '@chocolate-soup-inc/cs-api-consumer-utils';
import {
  CommonButton,
  ControlledTextField,
  LoadingPage,
  useAuthContext,
} from '@chocolate-soup-inc/cs-frontend-components';
import { LoginWithGoogle } from './LoginWithGoogleButton';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { toast } from 'react-toastify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { FORGOT_PASSWORD_PATH, ROOT_PATH } from '../../../routes/paths';
import { AuthPage } from '../shared/AuthPage';
import { PasswordRequirement } from '../shared/PasswordRequirement';
import { JOI_PASSWORD_RULES } from '../shared/utils';

type TFormFields = {
  email: string;
  password: string;
};

type TNewPasswordFormFields = {
  password: string;
  passwordConfirmation: string;
};

export const LoginPage = () => {
  const navigate = useNavigate();
  const { googleLoginEnabled, passwordLoginEnabled } = usePublicCompanyContext();
  const { configured } = useAuthContext();

  const [user, setUser] = useState<any>();
  const [initialLoading, setInitialLoading] = useState<boolean>(true);

  const [searchParams, setSearchParams] = useSearchParams();

  const userEmail = useMemo(() => {
    return searchParams.get('userEmail');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const code = useMemo(() => {
    return searchParams.get('userCode');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { state: customState } = useLocation();

  useEffect(() => {
    if (userEmail != null && code != null && configured) {
      setSearchParams({});
      Auth.signIn(userEmail, code)
        .then((userData) => {
          if (userData.challengeName === 'NEW_PASSWORD_REQUIRED') {
            setInitialLoading(false);
            setUser(userData);
          }
        })
        .catch(() => {
          setInitialLoading(false);
        });
    } else if (configured) {
      setInitialLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, configured, userEmail]);

  const onLoginWithGoogleClick = useCallback(() => {
    if (configured) {
      Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google, customState: customState?.location });
    }
  }, [configured, customState]);

  const onSubmit = useCallback(
    async (data: TFormFields) => {
      if (configured) {
        try {
          const userData = await Auth.signIn(data.email, data.password);

          if (userData.challengeName === 'NEW_PASSWORD_REQUIRED') {
            setUser(userData);
          }
        } catch (error: any) {
          toast.error(error?.message || 'There was an error trying to sign-in.');
        }
      }
    },
    [configured],
  );

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<TFormFields>({
    resolver: joiResolver(
      Joi.object({
        email: Joi.string()
          .email({ tlds: { allow: false } })
          .required(),
        password: Joi.string().required(),
      }),
    ),
  });

  const {
    control: newPasswordControl,
    handleSubmit: newPasswordHandleSubmit,
    formState: { isSubmitting: newPasswordIsSubmitting },
    watch: newPasswordWatch,
  } = useForm<TNewPasswordFormFields>({
    resolver: joiResolver(Joi.object(JOI_PASSWORD_RULES)),
  });

  const { password, passwordConfirmation } = newPasswordWatch();

  const onNewPasswordSubmit = useCallback(
    async (data: TNewPasswordFormFields) => {
      try {
        await Auth.completeNewPassword(user, data.password);
        if (userEmail) {
          await Auth.signIn(userEmail, data.password);
        } else {
          navigate(ROOT_PATH);
        }
      } catch (error: any) {
        toast.error(error?.message || 'There was an error trying to change the password.');
      }
    },
    [navigate, user, userEmail],
  );

  const navigateToForgotPassword = useCallback(() => {
    navigate(FORGOT_PASSWORD_PATH);
  }, [navigate]);

  const shouldShowNewPasswordForm = useMemo(() => {
    return user != null && passwordLoginEnabled;
  }, [passwordLoginEnabled, user]);

  if (initialLoading || !configured) return <LoadingPage />;

  return (
    <AuthPage
      supportText={shouldShowNewPasswordForm ? undefined : 'to continue to Chocolate Soup'}
      title={shouldShowNewPasswordForm ? 'New password' : 'Sign in'}
    >
      {googleLoginEnabled && <LoginWithGoogle onClick={onLoginWithGoogleClick} />}
      {googleLoginEnabled && passwordLoginEnabled && <p className={styles.supportText}>or</p>}
      {!shouldShowNewPasswordForm && passwordLoginEnabled && (
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <ControlledTextField
            control={control}
            label='Email'
            multiline={false}
            name='email'
            variant='outlined'
            type='email'
          />
          <ControlledTextField
            control={control}
            label='Password'
            multiline={false}
            name='password'
            variant='outlined'
            type='password'
          />
          <CommonButton className={styles.formButton} label='Sign in' loading={isSubmitting} type='submit' />
          <CommonButton label='Forgot password?' onClick={navigateToForgotPassword} variant='text' />
        </form>
      )}
      {shouldShowNewPasswordForm && (
        <form className={styles.form} onSubmit={newPasswordHandleSubmit(onNewPasswordSubmit)}>
          <ControlledTextField
            control={newPasswordControl}
            label='Password'
            multiline={false}
            name='password'
            variant='outlined'
            type='password'
          />
          <ControlledTextField
            control={newPasswordControl}
            label='Password Confirmation'
            multiline={false}
            name='passwordConfirmation'
            variant='outlined'
            type='password'
          />
          <PasswordRequirement password={password} passwordConfirmation={passwordConfirmation} />
          <CommonButton
            className={styles.formButton}
            label='Update password'
            loading={newPasswordIsSubmitting}
            type='submit'
          />
        </form>
      )}
    </AuthPage>
  );
};
