import { TDeliveryMethod, TListCompanyEmployeesQuery } from '../../../generated/graphql';

type TEmployee = Exclude<TListCompanyEmployeesQuery['listCompanyEmployees']['items'][number], null | undefined>;

const filterByField = <Field extends keyof TEmployee, Value extends TEmployee[Field]>(
  employee: TEmployee,
  field: Field,
  value: Value,
) => {
  if (value === undefined) {
    return true;
  }
  return employee[field] == value;
};

export const filterByDeliveryMethod = (employee: TEmployee, value: TDeliveryMethod | undefined) => {
  if (value === 'home') {
    return employee.address != null;
  }
  if (value === 'office') {
    return employee.address === null;
  }
  return true;
};

export const filterByOfficeId = (employee: TEmployee, value: string | undefined) => {
  if (value === '') return true;
  return filterByField(employee, 'officeId', value);
};
