import { Icon } from '@chocolate-soup-inc/cs-frontend-components';
import styles from './PasswordRequirement.module.scss';
import clsx from 'clsx';
import { useMemo } from 'react';
import {
  MIN_CHARS,
  MSG_MIN,
  MSG_NUMBER,
  MSG_SPECIAL,
  MSG_UPPERCASE,
  REGEX_NUMBER,
  REGEX_SPECIAL,
  REGEX_UPPERCASE,
} from './utils';

type TPasswordRequirementProps = {
  password: string;
  passwordConfirmation: string;
};

type TPasswordMessage = { message: string; valid: boolean };

type TPasswordFieldValidation = Record<
  'hasLength' | 'hasUppercase' | 'hasNumber' | 'hasSpecial' | 'isMatching',
  TPasswordMessage
>;
export const PasswordRequirement = ({ password, passwordConfirmation }: TPasswordRequirementProps) => {
  const passwordValidation: TPasswordFieldValidation = useMemo(
    () => ({
      hasLength: { message: MSG_MIN, valid: password?.length >= MIN_CHARS },
      hasUppercase: { message: MSG_UPPERCASE, valid: REGEX_UPPERCASE.test(password) },
      hasNumber: { message: MSG_NUMBER, valid: REGEX_NUMBER.test(password) },
      hasSpecial: { message: MSG_SPECIAL, valid: REGEX_SPECIAL.test(password) },
      isMatching: { message: 'Both passwords must match', valid: !!password && password === passwordConfirmation },
    }),
    [password, passwordConfirmation], // eslint-disable-line
  );

  return (
    <div>
      {Object.entries(passwordValidation).map(([name, { valid, message }]) => {
        return (
          <div className={clsx(styles.passwordRequirement)} key={name}>
            <Icon
              className={clsx(styles.deliveryOverviewBoxIcon, valid ? styles.valid : styles.invalid)}
              icon={valid ? 'check' : 'close'}
            />
            <span className={clsx(valid ? styles.valid : styles.invalid)}>{message}</span>
          </div>
        );
      })}
    </div>
  );
};
