import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Chip,
  ErrorPage,
  Icon,
  Loading,
  LoadingPage,
  readableDate,
  TableInner,
  Tooltip,
} from '@chocolate-soup-inc/cs-frontend-components';
import { quickScore } from 'quick-score';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  TDeliveryMethod,
  TListCompanyEmployeesQuery,
  TListOfficesQuery,
  useGetOfficeLazyQuery,
} from '../../../generated/graphql';
import _ from 'lodash';
import { EMPLOYEE_ACCOUNT_PATH, EMPLOYEE_PATH } from '../../../routes/paths';
import { usePrivateCompanyContext } from '../../../routes/outlets/PrivateCompanyOutlet';
import { useQueryAllEmployees } from '../../../entities/employee/shared';
import { CellContext } from '@tanstack/react-table';
import { TablePage } from '../../../components/TablePage/TablePage';

import styles from './EmployeeManagement.module.scss';
import { EmployeesMissingInfoAlert } from './EmployeesMissingInfoAlert';
import { Filters, TFiltersProps } from '../../../components/filters/Filters';
import { EmployeesWithAddressAlert } from './EmployeesWithAddressAlert';
import { filterByDeliveryMethod, filterByOfficeId } from './filters';
import { useQueryAllOffices } from '../../../entities/office/shared';
import clsx from 'clsx';
import tableStyles from '../../../components/TablePage/TablePage.module.scss';

type TEmployee = Exclude<TListCompanyEmployeesQuery['listCompanyEmployees']['items'][number], null | undefined>;
type TOffice = Exclude<TListOfficesQuery['listOffices']['items'][number], null | undefined>;

const OfficeTableCell = ({ cell }: CellContext<TEmployee, unknown>) => {
  const { id: companyId } = usePrivateCompanyContext();
  const { officeId } = cell.row.original;

  const [loadOffice, { loading, error, data }] = useGetOfficeLazyQuery({
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (officeId) loadOffice({ variables: { id: officeId, companyId } });
  }, [companyId, officeId, loadOffice]);

  if (error) return <ErrorPage error={error} />;
  if (loading) return <Loading className={clsx(tableStyles.tableLoading)} />;

  return <div className={clsx(tableStyles.tableSpaceSecondary)}>{data?.getOffice?.name || 'N/A'}</div>;
};
OfficeTableCell.displayName = 'OfficeTableCell';

export const EmployeeManagement = () => {
  const navigate = useNavigate();

  const { id: companyId, deliveryMethod, employeeBirthdayActivated } = usePrivateCompanyContext();

  const { data, error, loading } = useQueryAllEmployees({
    companyId,
  });

  const employees: TEmployee[] = useMemo(() => {
    return _.compact(data?.listCompanyEmployees?.items || []);
  }, [data]);

  const officeQuery = useQueryAllOffices({
    companyId: companyId,
  });

  const offices = useMemo(() => {
    const items = officeQuery.data?.listOffices?.items || [];
    return items as TOffice[];
  }, [officeQuery.data?.listOffices?.items]);

  const officeOptions = useMemo(() => {
    return offices.map((office) => ({ label: office.name, value: office.id }));
  }, [offices]);

  const getRowId = useCallback((employee: TEmployee) => {
    return employee.id;
  }, []);

  const onRowClick = useCallback(
    (id: string) => {
      navigate(
        generatePath(`${EMPLOYEE_PATH}/${EMPLOYEE_ACCOUNT_PATH}`, {
          employeeId: id,
        }),
      );
    },
    [navigate],
  );

  const [employeeNameFilter, setEmployeeNameFilter] = useState<string>();
  const [pauseStatusFilter, setPauseStatusFilter] = useState<string>();
  const [deliveryMethodFilter, setDeliveryMethodFilter] = useState<string>();
  const [officeFilter, setOfficeFilter] = useState<string>();

  const filterByEmployeeName = useCallback(
    (e: TEmployee) => {
      if (
        employeeNameFilter != null &&
        employeeNameFilter !== '' &&
        (e.fullName == null || quickScore(e.fullName, employeeNameFilter) < 0.7)
      ) {
        return false;
      }

      return true;
    },
    [employeeNameFilter],
  );

  const filterByPauseStatus = useCallback(
    (e: TEmployee) => {
      if (
        (e.isPauseEnabled && pauseStatusFilter === 'pauseDisabled') ||
        (!e.isPauseEnabled && pauseStatusFilter === 'pauseEnabled')
      ) {
        return false;
      }

      return true;
    },
    [pauseStatusFilter],
  );

  const filters = useMemo(() => {
    const filtersList: TFiltersProps['filters'] = [];

    filtersList.push({
      type: 'textInput',
      label: 'Employee Name',
      onChange: (v) => {
        if (v == null) setEmployeeNameFilter(undefined);
        else setEmployeeNameFilter(v as string);
      },
      value: employeeNameFilter,
    });

    filtersList.push({
      includeEmptyOption: true,
      type: 'singleSelect',
      label: 'Pause Status',
      onChange: (v) => {
        if (v == null) setPauseStatusFilter(undefined);
        else setPauseStatusFilter(v as string);
      },
      options: [
        {
          label: 'Pause Enabled',
          value: 'pauseEnabled',
        },
        {
          label: 'Pause Disabled',
          value: 'pauseDisabled',
        },
      ],
      value: pauseStatusFilter,
    });

    if (deliveryMethod === 'hybrid') {
      filtersList.push({
        includeEmptyOption: true,
        type: 'singleSelect',
        label: 'Delivery Method',
        onChange: (v) => {
          if (v == null) setDeliveryMethodFilter(undefined);
          else setDeliveryMethodFilter(v as string);
        },
        options: [
          {
            label: 'Home',
            value: 'home',
          },
          {
            label: 'Office',
            value: 'office',
          },
        ],
        value: deliveryMethodFilter,
      });
    }

    if (officeOptions.length > 1) {
      filtersList.push({
        includeEmptyOption: true,
        type: 'singleSelect',
        label: 'Office',
        onChange: (v) => {
          if (v == null) setOfficeFilter(undefined);
          else setOfficeFilter(v as string);
        },
        options: officeOptions,
        value: officeFilter,
      });
    }

    return filtersList;
  }, [employeeNameFilter, pauseStatusFilter, deliveryMethod, deliveryMethodFilter, officeFilter, officeOptions]);

  const filteredEmployees = useMemo(() => {
    const output = employees.filter((e) => {
      return (
        filterByEmployeeName(e) &&
        filterByPauseStatus(e) &&
        filterByDeliveryMethod(e, deliveryMethodFilter as TDeliveryMethod) &&
        filterByOfficeId(e, officeFilter)
      );
    });
    return output;
  }, [employees, filterByEmployeeName, filterByPauseStatus, deliveryMethodFilter, officeFilter]);

  if (error) return <ErrorPage error={error} />;
  if (loading) return <LoadingPage />;

  return (
    <TablePage className={styles.employeesTablePage} title={`Employee Management (${employees.length})`}>
      <EmployeesMissingInfoAlert />
      <EmployeesWithAddressAlert />
      <Filters filters={filters} />
      <div className={tableStyles.tableContainer}>
        <TableInner<TEmployee>
          className={styles.employeesTable}
          data={filteredEmployees}
          // data={[...employees, ...employees, ...employees, ...employees, ...employees]}
          emptyText={
            employees.length === 0
              ? 'There are no employees registered.'
              : 'There are no employees for the selected filters.'
          }
          expandable={false}
          fixedHeader={true}
          getRowId={getRowId}
          hoverableRows={true}
          virtual={true}
          onRowClick={(row) => {
            onRowClick(row.original.id);
          }}
          columns={[
            {
              header: 'Full Name',
              cell: ({ cell }) => {
                const { address, hireDate, birthDate, fullName, isPauseEnabled, pausePeriod } = cell.row.original;
                let text: string | undefined;
                if (pausePeriod) {
                  const { fromDate, toDate } = pausePeriod;

                  if (fromDate != null) {
                    text = `from ${readableDate(fromDate)}`;
                  }

                  if (toDate != null) {
                    text = `${text}to ${readableDate(toDate)}`;
                  }
                }

                return (
                  <div className={styles.rowNameColumn}>
                    {isPauseEnabled && (
                      <Tooltip message={`Pause enabled for this employee ${text}.`}>
                        <Icon icon='pause_circle' />
                      </Tooltip>
                    )}
                    <span>{fullName}</span>
                    {_.isEmpty(address) && deliveryMethod === TDeliveryMethod.Home && (
                      <Chip
                        className={styles.errorChip}
                        label='Missing address'
                        readonly={true}
                        selected={true}
                        variant='suggestion'
                      />
                    )}
                    {deliveryMethod !== TDeliveryMethod.Office && address?.missingInfo && (
                      <Chip
                        className={styles.errorChip}
                        label='Address incomplete'
                        readonly={true}
                        selected={true}
                        variant='suggestion'
                      />
                    )}
                    {(hireDate == null || hireDate === '') && (
                      <Chip
                        className={styles.errorChip}
                        label='Missing hire date'
                        readonly={true}
                        selected={true}
                        variant='suggestion'
                      />
                    )}
                    {(birthDate == null || birthDate === '') && employeeBirthdayActivated && (
                      <Chip
                        className={styles.errorChip}
                        label='Missing birth date'
                        readonly={true}
                        selected={true}
                        variant='suggestion'
                      />
                    )}
                  </div>
                );
              },
            },
            // {
            //   header: 'Email',
            //   cell: ({ cell }) => cell.row.original.email || '-',
            // },
            {
              header: 'Hire Date',
              accessorKey: 'hireDate',
              cell: ({ cell }) => {
                return (
                  <div className={clsx(tableStyles.tableSpaceTertiary)}>
                    <span>{readableDate(cell.row.original.hireDate)}</span>
                  </div>
                );
              },
            },
            {
              header: 'Office',
              cell: OfficeTableCell,
            },
          ]}
        />
      </div>
    </TablePage>
  );
};
