import ReactDOM from 'react-dom/client';
import { BrowserRouter, generatePath, useLocation, useNavigate } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { APIProvider } from './contexts/api';
import AppRoutes from './routes';
import { AuthProvider } from '@chocolate-soup-inc/cs-frontend-components';
import { ToastContainer } from 'react-toastify';

import '@chocolate-soup-inc/cs-frontend-components/index.css';
import 'react-toastify/dist/ReactToastify.css';
import './index.scss';
import { useCallback } from 'react';
import { LOGIN_PATH, ROOT_PATH } from './routes/paths';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const CustomAuthProvider = () => {
  const navigate = useNavigate();
  const { state: locationState } = useLocation();

  const onSignOut = useCallback(() => {
    navigate(generatePath(LOGIN_PATH));
  }, [navigate]);

  const onSignIn = useCallback(() => {
    let path = ROOT_PATH;
    if (locationState) {
      const { href, origin } = JSON.parse(locationState.location);

      if (href && origin) {
        path = href.replace(origin, '');
      }
    }

    navigate(generatePath(path));
  }, [locationState, navigate]);

  return (
    <AuthProvider
      listenToEventsProps={{
        onSignIn,
        onSignOut,
        onDefault: (payload) => {
          if (payload.event === 'customOAuthState') {
            const { href, origin } = JSON.parse(payload.data);

            if (href && origin) {
              navigate(href.replace(origin, ''));
            }
          }
        },
      }}
    >
      <APIProvider>
        <ToastContainer autoClose={10000} position='top-center' hideProgressBar={true} theme='dark' />
        <AppRoutes />
      </APIProvider>
    </AuthProvider>
  );
};

root.render(
  <BrowserRouter>
    <CustomAuthProvider />
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
