import Joi from 'joi';

export const MIN_CHARS = 8;
export const MSG_MIN = `Must be at least ${MIN_CHARS} characters long.`;
export const REGEX_UPPERCASE = new RegExp('(?=.*[A-Z])');
export const MSG_UPPERCASE = 'Must have at least one uppercase letter.';
export const REGEX_NUMBER = new RegExp('(?=.*[0-9])');
export const MSG_NUMBER = 'Must have at least one numeric digit.';
export const REGEX_SPECIAL = new RegExp('(?=.*[*@!$#&])');
export const MSG_SPECIAL = 'Must have at least one special character (* @ ! $ # &)';

export const JOI_PASSWORD_RULES = {
  password: Joi.string()
    .label('Password')
    .min(MIN_CHARS)
    .message(`{{#label}} ${MSG_MIN}`)
    .pattern(REGEX_UPPERCASE)
    .message(`{{#label}} ${MSG_UPPERCASE}`)
    .pattern(REGEX_NUMBER)
    .message(`{{#label}} ${MSG_NUMBER}`)
    .pattern(REGEX_SPECIAL)
    .message(`{{#label}} ${MSG_SPECIAL}`)
    .required(),
  passwordConfirmation: Joi.string()
    .equal(Joi.ref('password'))
    .required()
    .label('Password confirmation')
    .messages({ 'any.only': '{{#label}} does not match' }),
};
