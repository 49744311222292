import { TControlledFormComponentProps } from '@chocolate-soup-inc/cs-frontend-components';
import _ from 'lodash';
import {
  TListOfficesQuery,
  TPrivateCompanyFieldsFragment,
  TWorkAnniversaryGiftVariations,
} from '../../generated/graphql';
import { getEmployeeDependantFormFields } from '../dependant/formFields';

type TGetEmployeeFormFieldsProps = {
  addDependants?: boolean;
  company: TPrivateCompanyFieldsFragment;
  offices?: TListOfficesQuery['listOffices']['items'];
};

export const getEmployeeFormFields = (props: TGetEmployeeFormFieldsProps) => {
  const { addDependants = false, company, offices } = props;

  const formFields: TControlledFormComponentProps[] = [
    {
      type: 'section',
      title: "Employee's required information.",
    },
    {
      label: 'Employee ID',
      name: 'externalId',
      type: 'textField',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      label: 'First Name',
      name: 'firstName',
      type: 'textField',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      label: 'Last Name',
      name: 'lastName',
      type: 'textField',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
  ];

  formFields.push({
    label: 'Hire Date',
    name: 'hireDate',
    type: 'datePicker',
    inputOptions: {
      className: 'datePickerField',
      clearDisabled: false,
    },
  });

  if (company.employeeBirthdayActivated) {
    formFields.push({
      label: 'Birth Date',
      name: 'birthDate',
      type: 'datePicker',
      inputOptions: {
        className: 'datePickerField',
        clearDisabled: false,
      },
    });
  }

  if (company.employeeNewHireKitActivated && company.hasCustomOnboardBrick) {
    formFields.push({
      label: 'Custom Onboard Brick',
      name: 'customOnboardBrick',
      type: 'textField',
    });
  }

  formFields.push({
    type: 'section',
    title: 'Optional Information',
  });

  if (offices && _.compact(offices).length > 0) {
    formFields.push({
      className: 'col-span-1',
      type: 'select',
      name: 'officeId',
      label: 'Office',
      inputOptions: {
        multiple: false,
        autoComplete: 'off',
        includeEmptyOption: true,
        options: [
          ..._.compact(offices).map((office) => ({
            label: office.name,
            value: office.id,
          })),
        ],
        variant: 'outlined',
      },
    });
  }

  formFields.push(
    {
      name: 'preferredFirstName',
      label: 'Preferred First Name',
      type: 'textField',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      name: 'email',
      label: 'Email',
      type: 'textField',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      name: 'phoneNumber',
      label: 'Phone number',
      type: 'textField',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
        transformIn: (data) => {
          if (Array.isArray(data)) {
            return data.join('\n');
          }

          return data;
        },
        transformOut: (data) => {
          if (data == null) return [];
          else {
            return data.split('\n');
          }
        },
      },
    },
    {
      className: 'col-span-2',
      name: 'foodPreferences',
      label: 'Allergies & Dietary Restrictions',
      type: 'textArea',
      inputOptions: {
        multiline: true,
        autoComplete: 'off',
      },
    },
  );

  if (company.deliveryMethod !== 'office') {
    formFields.push(
      {
        type: 'section',
        title: 'Delivery Address',
      },
      {
        className: 'col-span-2',
        type: 'textField',
        label: 'Address line 1',
        name: 'address.address1',
        inputOptions: {
          multiline: false,
          autoComplete: 'off',
        },
      },
      {
        className: 'col-span-2',
        type: 'textField',
        label: 'Address line 2',
        name: 'address.address2',
        inputOptions: {
          multiline: false,
          autoComplete: 'off',
        },
      },
      {
        type: 'textField',
        label: 'City',
        name: 'address.city',
        inputOptions: {
          multiline: false,
          autoComplete: 'off',
        },
      },
      {
        type: 'textField',
        label: 'State / Province',
        name: 'address.state',
        inputOptions: {
          multiline: false,
          autoComplete: 'off',
        },
      },
      {
        type: 'countrySelect',
        label: 'Country',
        name: 'address.country',
        inputOptions: {
          variant: 'outlined',
          autoComplete: 'off',
          includeEmptyOption: true,
        },
      },
      {
        type: 'textField',
        label: 'Zip Code',
        name: 'address.zipCode',
        inputOptions: {
          multiline: false,
          autoComplete: 'off',
        },
      },
    );
  }

  if (addDependants) {
    formFields.push(
      {
        type: 'section',
        title: 'Dependants',
      },
      {
        type: 'fieldArray',
        name: 'dependants',
        addLabel: 'Add Dependant',
        label: 'Dependant #{{counter}}',
        fields: getEmployeeDependantFormFields({ company }),
      },
    );
  }

  formFields.push(
    {
      type: 'section',
      title: 'SWAG Information',
    },
    {
      name: 'tShirtSize',
      label: 'T-Shirt Size',
      type: 'textField',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      name: 'sweaterSize',
      label: 'Sweater Size',
      type: 'textField',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
  );

  const canDonateAnniversaryGift =
    company.employeeWorkAnniversaryActivated &&
    company.workAnniversaryGiftVariation === TWorkAnniversaryGiftVariations.Deluxe;
  if (company.employeeBirthdayActivated || canDonateAnniversaryGift) {
    formFields.push({
      type: 'section',
      title: 'Donation Information',
    });
    if (company.employeeBirthdayActivated) {
      formFields.push({
        className: 'col-span-2',
        type: 'switch',
        label: 'Donate birthday gift to charity',
        name: 'donateBirthdayGift',
      });
    }
    if (canDonateAnniversaryGift) {
      formFields.push({
        className: 'col-span-2',
        type: 'switch',
        label: 'Donate work anniversary gift to charity',
        name: 'donateWorkAnniversaryGift',
      });
    }
  }

  return formFields;
};
