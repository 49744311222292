import Joi from 'joi';
import _ from 'lodash';
import { phoneNumberExtension } from '../../shared/validations/phoneNumber';

export const getContactSchema = () => {
  return Joi.object()
    .unknown(false)
    .keys({
      name: Joi.string().trim().required(),
      email: Joi.string().trim().required(),
      phoneNumber: Joi.extend(phoneNumberExtension).phoneNumber().required(),
    });
};

export const getContactDefaultValues = (contact?: Record<string, any> | null) => {
  return _.pick(contact || {}, 'name', 'email', 'phoneNumber');
};
