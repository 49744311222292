import React, { useMemo } from 'react';
import { ApolloProvider } from '@apollo/client';
import { privateClient, publicClient } from '../config/apollo/api';
import { useAuthContext } from '@chocolate-soup-inc/cs-frontend-components';

export const APIProvider = ({ children }: { children: React.ReactNode }) => {
  const { sessionToken: loggedIn } = useAuthContext();

  const client = useMemo(() => {
    if (loggedIn) return privateClient;
    else return publicClient;
  }, [loggedIn]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
