import { Auth } from '@chocolate-soup-inc/cs-api-consumer-utils';

export const configAuth = ({
  userPoolId,
  userPoolClientId,
  authDomain,
}: {
  userPoolId: string;
  userPoolClientId: string;
  authDomain: string;
}) => {
  const redirectSignIn = `${window.location.origin}${process.env.REACT_APP_CALLBACK_PATH}`;
  const redirectSignOut = `${window.location.origin}${process.env.REACT_APP_LOGOUT_PATH}`;

  if (userPoolId == null || userPoolClientId == null || authDomain == null) {
    throw new Error('Bad auth configuration params.');
  }

  const authOptions = {
    userPoolId,
    userPoolWebClientId: userPoolClientId,
    region: process.env.REACT_APP_REGION,
    authenticationFlowType: 'USER_SRP_AUTH',
    clientMetadata: {
      app: 'client-portal',
    },
    // (optional) - Hosted UI configuration
    oauth: {
      domain: authDomain,
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn,
      redirectSignOut,
      responseType: 'code',
    },
  };

  Auth.configure(authOptions);
};
