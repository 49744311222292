import Joi from 'joi';
import _ from 'lodash';
import { getAddressDefaultValues, getAddressSchema } from '../address/schema';
import { getContactDefaultValues, getContactSchema } from '../contact/schema';
import { clearableField } from '../shared/clearableField';
import { TGetOffice } from './shared';

export const getOfficeSchema = () => {
  const schema = Joi.object()
    .unknown(false)
    .keys({
      id: Joi.forbidden(),
      companyId: Joi.forbidden(),
      name: Joi.string().trim().empty(Joi.valid(null, '')).required(),
      legalName: clearableField(Joi.string().trim()),
      address: getAddressSchema(true).required(),
      contact: getContactSchema(),
    });

  return schema;
};

export const getOfficeDefaultValues = (office?: TGetOffice | null) => {
  return {
    ..._.pick(office || {}, ['name', 'legalName']),
    address: getAddressDefaultValues(office?.address),
    contact: getContactDefaultValues(office?.contact),
  };
};
