import { DocumentNode, OperationVariables, QueryHookOptions, QueryResult, TypedDocumentNode } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { subscribe } from '../../config/apollo/cache';

type TUseQuerAllProps<TQuery, TVariables extends OperationVariables, TSubscriptionVariables> = {
  subscriptionDoc?: DocumentNode | TypedDocumentNode;
  subscriptionVariables?: TSubscriptionVariables;
  useQuery: (baseOptions: QueryHookOptions<TQuery, TVariables>) => QueryResult<TQuery, TVariables>;
  variables: TVariables;
};

export const useQueryAll = <TQuery, TVariables extends OperationVariables, TSubscriptionVariables = undefined>(
  props: TUseQuerAllProps<TQuery, TVariables, TSubscriptionVariables>,
) => {
  const { subscriptionDoc, subscriptionVariables, useQuery, variables } = props;
  const { data, error, fetchMore, loading } = useQuery({
    variables,
    fetchPolicy: 'cache-first',
  });

  const nextToken = useMemo(() => {
    if (data && typeof data === 'object') {
      for (const value of Object.values(data)) {
        if (value && typeof value === 'object' && value.nextToken != null) {
          return value.nextToken;
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (nextToken) {
      fetchMore({
        variables: {
          nextToken,
        },
      });
    }
  }, [fetchMore, nextToken]);

  useEffect(() => {
    if (subscriptionDoc && subscriptionVariables) {
      subscribe({
        query: subscriptionDoc,
        variables: subscriptionVariables,
      });
    }
  }, [subscriptionDoc, subscriptionVariables]);

  return {
    data,
    error,
    loading,
  };
};
