import { getDateWithTimezoneFixed } from '@chocolate-soup-inc/cs-frontend-components';
import Joi from 'joi';
import _ from 'lodash';
import { TListOfficesQuery, TPrivateCompanyFieldsFragment } from '../../generated/graphql';
import { phoneNumberExtension } from '../../shared/validations/phoneNumber';
import { getAddressDefaultValues, getAddressSchema, makeAddressSchemaOptional } from '../address/schema';
import { getEmployeeDependantSchema } from '../dependant/schema';
import { clearableField } from '../shared/clearableField';
import { AWSDateSchema } from '../shared/utils';
import { TGetEmployee } from './shared';

type TGetEmployeeSchemaProps = {
  addDependants?: boolean;
  company: TPrivateCompanyFieldsFragment;
  offices?: TListOfficesQuery['listOffices']['items'];
};

export const getEmployeeSchema = (props: TGetEmployeeSchemaProps) => {
  // const { company, offices } = props;
  const { offices } = props;

  let employeeSchema = Joi.object()
    .unknown(false)
    .keys({
      id: Joi.forbidden(),
      // active: Joi.boolean().empty(Joi.valid(null, '')).default(true),
      // externalId: Joi.string().trim().empty(Joi.valid('')).default(null).allow(null),
      companyId: Joi.forbidden(),
      officeId: clearableField(
        Joi.string()
          .trim()
          .valid(..._.compact(offices).map((o) => o.id))
          .optional(),
      ),
      externalId: clearableField(Joi.string().trim()),
      firstName: Joi.string().trim().required(),
      preferredFirstName: clearableField(Joi.string().trim()),
      lastName: Joi.string().trim().required(),
      hireDate: clearableField(AWSDateSchema),
      birthDate: clearableField(AWSDateSchema),
      email: clearableField(
        Joi.string()
          .trim()
          .lowercase()
          .email({
            tlds: {
              allow: false,
            },
          }),
      ),
      phoneNumber: Joi.alternatives(
        clearableField(Joi.extend(phoneNumberExtension).phoneNumber()),
        clearableField(Joi.array().items(Joi.extend(phoneNumberExtension).phoneNumber())),
      ),
      foodPreferences: clearableField(Joi.string().trim()),
      customOnboardBrick: clearableField(Joi.string().trim()),
      title: clearableField(Joi.string().trim()),
      organization: clearableField(Joi.string().trim()),
      deliveryMethod: Joi.string().empty(Joi.valid(null, '')).valid('home', 'office', 'default'),
      tShirtSize: clearableField(Joi.string().trim()),
      sweaterSize: clearableField(Joi.string().trim()),
      donateBirthdayGift: Joi.boolean().empty(Joi.valid(null, '')).default(false),
      donateWorkAnniversaryGift: Joi.boolean().empty(Joi.valid(null, '')).default(false),
      // address: getAddressSchema(),
      dependants: Joi.array().items(getEmployeeDependantSchema()).optional(),
    });

  // if (company.employeeNewHireKitActivated || company.employeeWorkAnniversaryActivated) {
  //   employeeSchema = employeeSchema.fork(['hireDate'], (schema) => schema.required());
  // }

  // if (company.employeeBirthdayActivated) {
  //   employeeSchema = employeeSchema.fork(['birthDate'], (schema) => schema.required());
  // }

  // if (company.deliveryMethod === 'home') {
  //   employeeSchema = employeeSchema.keys({
  //     address: getAddressSchema().required(),
  //   });
  // } else {
  //   employeeSchema = employeeSchema.keys({
  //     address: Joi.when('.', {
  //       is: makeAddressSchemaOptional(),
  //       then: Joi.any().custom(() => null),
  //       otherwise: getAddressSchema(),
  //     }),
  //   });
  // }

  employeeSchema = employeeSchema.keys({
    address: Joi.when('.', {
      is: makeAddressSchemaOptional(),
      then: Joi.any().custom(() => null),
      otherwise: getAddressSchema(),
    }),
  });

  return employeeSchema;
};

export const getEmployeeDefaultValues = (employee?: TGetEmployee | null) => {
  return {
    ..._.pick(
      employee || {},
      'externalId',
      'firstName',
      'preferredFirstName',
      'lastName',
      // 'hireDate',
      // 'birthDate',
      'email',
      'phoneNumber',
      'foodPreferences',
      'tShirtSize',
      'sweaterSize',
      'donateBirthdayGift',
      'donateWorkAnniversaryGift',
    ),
    officeId: employee?.officeId || employee?.office?.id,
    address: getAddressDefaultValues(employee?.address),
    birthDate: getDateWithTimezoneFixed(employee?.birthDate),
    hireDate: getDateWithTimezoneFixed(employee?.hireDate),
  };
};
