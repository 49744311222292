import { ApolloClient, useApolloClient } from '@apollo/client';
import {
  getFragmentName,
  subscribe as subscribeBase,
  TConnectionItem,
} from '@chocolate-soup-inc/cs-api-consumer-utils';
import { useEffect } from 'react';
import { serializeError } from 'serialize-error';
import { onSubscriptionData } from '../../config/apollo/cache';
import {
  GetOfficeDocument,
  OfficeFieldsFragmentDoc,
  OnCompanyOfficeChangedDocument,
  TGetOfficeQuery,
  TGetOfficeQueryVariables,
  TListOfficesQuery,
  TListOfficesQueryVariables,
  TOnCompanyOfficeChangedSubscription,
  TOnCompanyOfficeChangedSubscriptionVariables,
  useGetOfficeLazyQuery,
  useListOfficesQuery,
} from '../../generated/graphql';
import { useFragmentOrFetch } from '../shared/useFragmentOrFetch';
import { useQueryAll } from '../shared/useQueryAll';

type TOnOfficeSubscriptionDataProps = {
  client: ApolloClient<object>;
  data?: TOnCompanyOfficeChangedSubscription;
  vars?: TOnCompanyOfficeChangedSubscriptionVariables;
};

const onOfficeSubscriptionData = (props: TOnOfficeSubscriptionDataProps) => {
  const { client, data, vars } = props;

  const { id, companyId, _deleted } = data?.onCompanyOfficeChanged || {};

  if (_deleted) {
    return onSubscriptionData(data as Record<string, any>, vars);
  } else if (id && companyId) {
    client
      .query<TGetOfficeQuery, TGetOfficeQueryVariables>({
        query: GetOfficeDocument,
        variables: {
          id,
          companyId,
        },
      })
      .then((response) => {
        const officeData = response?.data?.getOffice as TConnectionItem;

        if (officeData) {
          return onSubscriptionData(
            {
              onCompanyOfficeChanged: officeData,
            },
            vars,
          );
        }
      })
      .catch((error) => {
        console.error('Error', serializeError(error));
      });
  }
};

export const useSubscribeToCompanyOfficeChanged = (variables: TOnCompanyOfficeChangedSubscriptionVariables) => {
  const client = useApolloClient();

  useEffect(() => {
    subscribeBase({
      client,
      query: OnCompanyOfficeChangedDocument,
      variables,
      onSubscriptionData: (data, vars) => {
        onOfficeSubscriptionData({
          client,
          data: data as TOnCompanyOfficeChangedSubscription,
          vars: vars as TOnCompanyOfficeChangedSubscriptionVariables,
        });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, JSON.stringify(variables)]);
};

export type TGetOffice = Exclude<TGetOfficeQuery['getOffice'], undefined | null>;

export const useFragmentOrFetchOffice = (variables: TGetOfficeQueryVariables) => {
  useSubscribeToCompanyOfficeChanged({
    companyId: variables.companyId,
  });

  return useFragmentOrFetch<TGetOffice, TGetOfficeQuery, TGetOfficeQueryVariables>({
    fragmentDoc: OfficeFieldsFragmentDoc,
    fragmentName: getFragmentName(OfficeFieldsFragmentDoc),
    useLazyQuery: useGetOfficeLazyQuery,
    variables,
    __typename: 'Office',
  });
};

export const useQueryAllOffices = (variables: TListOfficesQueryVariables) => {
  useSubscribeToCompanyOfficeChanged({
    companyId: variables.companyId,
  });

  return useQueryAll<TListOfficesQuery, TListOfficesQueryVariables>({
    useQuery: useListOfficesQuery,
    variables,
  });
};
