import styles from './AuthPage.module.scss';
import logo from '../../../assets/logo256.png';
import { ReactNode } from 'react';

type TAuthPageProps = {
  children: ReactNode;
  supportText?: string;
  title?: string;
};

export const AuthPage = (props: TAuthPageProps) => {
  const { children, supportText, title } = props;

  return (
    <div className={styles.authPage}>
      <div className={styles.authPageContent}>
        <img className={styles.logo} src={logo} alt='Logo' />
        {title && <h1 className={styles.title}>{title}</h1>}
        {supportText && <p className={styles.supportText}>{supportText}</p>}
        {children}
      </div>
    </div>
  );
};
