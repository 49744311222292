import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
import styles from '../shared/AuthPage.module.scss';
import { useCallback } from 'react';
import { Auth } from '@chocolate-soup-inc/cs-api-consumer-utils';
import { generatePath, useNavigate } from 'react-router-dom';
import { LOGIN_PATH } from '../../../routes/paths';
import { toast } from 'react-toastify';
import { CommonButton, ControlledTextField } from '@chocolate-soup-inc/cs-frontend-components';
import { AuthPage } from '../shared/AuthPage';

type TFormFields = {
  email: string;
};

export const ForgotPasswordPage = () => {
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<TFormFields>({
    resolver: joiResolver(
      Joi.object({
        email: Joi.string()
          .email({ tlds: { allow: false } })
          .required(),
      }),
    ),
  });

  const onSubmit = useCallback(
    async (data: TFormFields) => {
      try {
        await Auth.forgotPassword(data.email);
        toast.success('If the email is registered, an email was sent to it.');
        navigate(generatePath(LOGIN_PATH));
      } catch (error: any) {
        toast.error(error?.message || 'There was an error trying to send the forgot password email.');
      }
    },
    [navigate],
  );

  const navigateToSignInPage = useCallback(() => {
    navigate(generatePath(LOGIN_PATH));
  }, [navigate]);

  return (
    <AuthPage
      supportText='Enter the email address you used when you joined and we’ll send you instructions to reset your password.'
      title='Forgot password?'
    >
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <ControlledTextField
          control={control}
          label='Email'
          multiline={false}
          name='email'
          variant='outlined'
          type='email'
        />
        <CommonButton
          className={styles.formButton}
          label='Send reset instructions'
          loading={isSubmitting}
          type='submit'
        />
        <CommonButton label='Back to login' onClick={navigateToSignInPage} variant='text' />
      </form>
    </AuthPage>
  );
};
