import { ApolloError } from '@apollo/client';
import { ConfirmationModal, ErrorPage, LoadingPage, TModalProps } from '@chocolate-soup-inc/cs-frontend-components';
import clsx from 'clsx';
import _ from 'lodash';
import { ForwardedRef, forwardRef } from 'react';
import { useEffect } from 'react';
import { ReactNode, useCallback, useState } from 'react';
import { useLayoutContext } from '../../pages/shared/Layout';

import styles from './TablePage.module.scss';

export type TConfirmationProps = Pick<
  TModalProps,
  'confirmLabel' | 'icon' | 'headline' | 'onConfirmClick' | 'open' | 'supportingText'
>;

export type TTablePageRef = {
  resetConfirmationModal: () => void;
  setConfirmationProps: (v?: TConfirmationProps) => void;
};

type TTablePageProps = {
  actionsLoading?: boolean;
  children?: ReactNode;
  className?: string;
  dataLoading?: boolean;
  error?: ApolloError;
  forwardedRef: ForwardedRef<TTablePageRef>;
  title?: string;
};

export const TablePageInner = (props: TTablePageProps) => {
  const { actionsLoading, children, className: propsClassName, dataLoading, error, forwardedRef, title } = props;

  const { className, fullGridClassName, titleClassName } = useLayoutContext();

  const [confirmationProps, setConfirmationPropsState] = useState<TConfirmationProps>({});

  const resetConfirmationModal = useCallback(() => {
    setConfirmationPropsState({});
  }, []);

  const setConfirmationProps = useCallback((newProps?: TConfirmationProps) => {
    if (newProps == null) {
      setConfirmationPropsState({});
    } else {
      setConfirmationPropsState(_.cloneDeep(newProps));
    }
  }, []);

  useEffect(() => {
    const v: TTablePageRef = {
      resetConfirmationModal,
      setConfirmationProps,
    };

    if (typeof forwardedRef === 'function') {
      forwardedRef(v);
    } else if (forwardedRef != null) {
      forwardedRef.current = v;
    }
  }, [forwardedRef, resetConfirmationModal, setConfirmationProps]);

  if (error) return <ErrorPage error={error} />;
  if (dataLoading)
    return (
      <div className={fullGridClassName}>
        <LoadingPage className={className} />
      </div>
    );

  return (
    <div className={clsx(fullGridClassName, styles.tablePageWrapper)}>
      {!_.isEmpty(confirmationProps) && (
        <ConfirmationModal
          {...confirmationProps}
          closeModal={resetConfirmationModal}
          confirmLoading={actionsLoading}
          onCancelClick={resetConfirmationModal}
        />
      )}
      <div className={clsx(className, propsClassName, styles.tablePage)}>
        {title && <h1 className={clsx(titleClassName, styles.tablePageTitle)}>{title}</h1>}
        {children}
      </div>
    </div>
  );
};

TablePageInner.displayName = 'TablePageInner';

export const TablePage = forwardRef<TTablePageRef, Omit<TTablePageProps, 'forwardedRef'>>((props, ref) => {
  return <TablePageInner {...props} forwardedRef={ref} />;
});

TablePage.displayName = 'TablePage';
