import { ApolloError, SingleExecutionResult } from '@apollo/client';
import {
  ErrorPage,
  LoadingPage,
  Modal,
  ModalForm,
  TControlledFormProps,
} from '@chocolate-soup-inc/cs-frontend-components';
import { joiResolver } from '@hookform/resolvers/joi';
import { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  TCreateEmployeeMutation,
  TCreateEmployeeMutationInput,
  useCreateEmployeeMutation,
} from '../../../generated/graphql';
import { ACCEPT_ADDRESS_PATH, EMPLOYEES_PATH, EMPLOYEE_ACCOUNT_PATH, EMPLOYEE_PATH } from '../../../routes/paths';
import { useEmployeeForm } from './useEmployeeForm';
import styles from './EmployeeForm.module.scss';
import { usePrivateCompanyContext } from '../../../routes/outlets/PrivateCompanyOutlet';
import { serializeError } from 'serialize-error';
import { toast } from 'react-toastify';
import _ from 'lodash';

export const EmployeeCreateForm = () => {
  const navigate = useNavigate();
  const company = usePrivateCompanyContext();

  const [create] = useCreateEmployeeMutation();

  const closeModal = useCallback(() => {
    navigate(generatePath(EMPLOYEES_PATH));
  }, [navigate]);

  const onSuccess = useCallback(
    (data: SingleExecutionResult<TCreateEmployeeMutation>) => {
      if (data.errors) {
        for (const error of data.errors) {
          console.error(serializeError(error));
          toast.error(error.message);
        }
      } else {
        const address = data.data?.createEmployee?.address;
        const { address1, address2, city, state, country, zipCode, googleValidated, missingInfo, addressFromGoogle } =
          address || {};

        if (
          data.data?.createEmployee?.id &&
          googleValidated &&
          !_.isEmpty(addressFromGoogle) &&
          (address1 !== addressFromGoogle?.address1 ||
            address2 !== addressFromGoogle?.address2 ||
            city !== addressFromGoogle?.city ||
            state !== addressFromGoogle?.state ||
            country !== addressFromGoogle?.country ||
            zipCode !== addressFromGoogle?.zipCode)
        ) {
          // SHOW OPTION TO CHOOSE ADDRESS VALIDATED BY GOOGLE
          navigate(
            generatePath(`${EMPLOYEES_PATH}/${EMPLOYEE_PATH}/${ACCEPT_ADDRESS_PATH}`, {
              employeeId: data.data.createEmployee.id,
            }),
          );
        } else {
          if (!_.isEmpty(address) && missingInfo) {
            toast.error(
              'The employee was created but the address is missing some required fields. While these fields are not filled, gifts will not be sent to this address.',
            );
          } else if (!_.isEmpty(address) && !googleValidated) {
            toast.warning(
              'The employee was successfully created but the address could not be validated by Google. Make sure it is correct otherwise it might be returned when sent.',
            );
          }

          if (data.data?.createEmployee?.id) {
            navigate(
              generatePath(`${EMPLOYEES_PATH}/${EMPLOYEE_PATH}/${EMPLOYEE_ACCOUNT_PATH}`, {
                employeeId: data.data.createEmployee.id,
              }),
            );
          } else {
            closeModal();
          }
        }
      }
    },
    [closeModal, navigate],
  );

  const onSubmitError = useCallback((error: ApolloError) => {
    if (error instanceof ApolloError) {
      for (const err of error.graphQLErrors) {
        console.error(serializeError(err));
        toast.error(err.message);
      }
    }
  }, []);

  const onSubmit = useCallback<TControlledFormProps<TCreateEmployeeMutationInput>['onValidSubmit']>(
    (formData) => {
      if (company?.id) {
        return create({
          variables: {
            input: {
              ...formData,
              companyId: company.id,
            },
          },
        })
          .then(onSuccess)
          .catch(onSubmitError);
      }
    },
    [company?.id, create, onSubmitError, onSuccess],
  );

  const { employeeFormFields, employeeSchema, loading, error } = useEmployeeForm();

  if (loading) {
    return (
      <Modal closeModal={closeModal}>
        <LoadingPage />
      </Modal>
    );
  }
  if (error) return <ErrorPage error={error} />;

  return (
    <ModalForm
      closeModal={closeModal}
      controlledFormProps={{
        className: styles.form,
        fields: employeeFormFields,
        formProps: {
          resolver: joiResolver(employeeSchema, {
            convert: true,
            abortEarly: false,
            stripUnknown: false,
          }),
        },
        onValidSubmit: onSubmit,
      }}
      headline='Create Employee'
      size='large'
    />
  );
};
