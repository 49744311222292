import { generatePath, useNavigate } from 'react-router-dom';
import { usePrivateCompanyContext } from '../../../routes/outlets/PrivateCompanyOutlet';
import { useCallback, useMemo } from 'react';
import { TListEmployee, useQueryAllEmployees } from '../../../entities/employee/shared';
import _ from 'lodash';
import { EMPLOYEES_PATH, EMPLOYEE_ACCOUNT_PATH, EMPLOYEE_PATH } from '../../../routes/paths';
import clsx from 'clsx';
import { TableInner } from '@chocolate-soup-inc/cs-frontend-components';
import { useLayoutContext } from '../../shared/Layout';

import styles from './EmployeesWithAddressAlert.module.scss';

type TEmployeesWithAddressAlertProps = {
  className?: string;
  backTo?: string;
};

export const EmployeesWithAddressAlert = (props: TEmployeesWithAddressAlertProps) => {
  const { className, backTo } = props;
  const { className: layotuClassName } = useLayoutContext();

  const navigate = useNavigate();

  const { id: companyId } = usePrivateCompanyContext();

  const { data } = useQueryAllEmployees({
    companyId,
  });

  const employeesWithAddressAlert: TListEmployee[] = useMemo(() => {
    return _.compact(data?.listCompanyEmployees?.items || []).filter((e) => {
      return e.address?.alert != null;
    });
  }, [data?.listCompanyEmployees?.items]);

  const getRowId = useCallback((employee: TListEmployee) => {
    return employee.id;
  }, []);

  const onRowClick = useCallback(
    (id: string) => {
      navigate(
        generatePath(`${EMPLOYEES_PATH}/${EMPLOYEE_PATH}/${EMPLOYEE_ACCOUNT_PATH}`, {
          employeeId: id,
        }),
        {
          state: {
            backTo,
          },
        },
      );
    },
    [navigate, backTo],
  );

  if (employeesWithAddressAlert.length === 0) {
    return null;
  }

  return (
    <div className={clsx(layotuClassName, className, styles.withAddressAlert)}>
      <div className={clsx(styles.withAddressAlertContent)}>
        <h2 className={styles.title}>Not validated addresses.</h2>
        <p className={styles.supportText}>
          {`These employees addresses are filled but we could not validate them on Google Maps. Please make sure they are correct.`}
        </p>
        <TableInner<TListEmployee>
          data={employeesWithAddressAlert}
          expandable={false}
          fixedHeader={false}
          getRowId={getRowId}
          hoverableRows={true}
          virtual={false}
          onRowClick={(row) => {
            onRowClick(row.original.id);
          }}
          columns={[
            {
              header: 'Full Name',
              accessorKey: 'fullName',
            },
          ]}
        />
      </div>
    </div>
  );
};
