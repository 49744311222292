import Joi from 'joi';
import _ from 'lodash';
import { clearableField } from '../shared/clearableField';

export const getAddressSchema = (requiredFields = false) => {
  const schema = Joi.object()
    .unknown(false)
    .keys({
      id: Joi.forbidden(),
      companyId: Joi.forbidden(),
      address1: clearableField(Joi.string().trim()),
      address2: clearableField(Joi.string().trim()),
      city: clearableField(Joi.string().trim()),
      state: clearableField(Joi.string().trim()),
      zipCode: clearableField(Joi.string().trim()),
      country: clearableField(Joi.string().trim()),
    });

  if (requiredFields) {
    return schema.fork(['address1', 'city', 'state', 'country', 'zipCode'], (s) => s.required());
  } else {
    return schema;
  }
};

export const makeAddressSchemaOptional = () => {
  return Joi.object({
    address1: Joi.string().trim().valid(null, ''),
    address2: Joi.string().trim().valid(null, ''),
    city: Joi.string().trim().valid(null, ''),
    state: Joi.string().trim().valid(null, ''),
    zipCode: Joi.string().trim().valid(null, ''),
    country: Joi.string().trim().valid(null, ''),
  });
};

export const getAddressDefaultValues = (address?: Record<string, any> | null) => {
  return _.pick(address || {}, 'address1', 'address2', 'city', 'state', 'zipCode', 'country');
};
