import { ApolloError, SingleExecutionResult } from '@apollo/client';
import { ErrorPage, LoadingPage, ModalForm, TControlledFormProps } from '@chocolate-soup-inc/cs-frontend-components';
import { joiResolver } from '@hookform/resolvers/joi';
import { useCallback, useMemo } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { dependantSchema, getEmployeeDependantDefaultValues } from '../../../entities/dependant/schema';
import { getEmployeeDependantFormFields } from '../../../entities/dependant/formFields';
import {
  TUpdateDependantMutation,
  TUpdateDependantMutationInput,
  useUpdateDependantMutation,
} from '../../../generated/graphql';
import styles from './EmployeeDependantForm.module.scss';
import { DEPENDANTS_PATH, EMPLOYEES_PATH, EMPLOYEE_PATH } from '../../../routes/paths';
import { usePrivateCompanyContext } from '../../../routes/outlets/PrivateCompanyOutlet';
import { useFragmentOrFetchEmployeeDependant } from '../../../entities/dependant/shared';
import { serializeError } from 'serialize-error';
import { toast } from 'react-toastify';

export const EmployeeDependantUpdateForm = () => {
  const navigate = useNavigate();
  const { employeeId, dependantId } = useParams();
  const company = usePrivateCompanyContext();

  const companyId = useMemo(() => company.id, [company.id]);

  const closeModal = useCallback(() => {
    navigate(generatePath(EMPLOYEES_PATH));
  }, [navigate]);

  const onBackClick = useCallback(() => {
    navigate(
      generatePath(`${EMPLOYEES_PATH}/${EMPLOYEE_PATH}/${DEPENDANTS_PATH}`, {
        employeeId: employeeId as string,
      }),
    );
  }, [employeeId, navigate]);

  const {
    data: dependant,
    error: queryError,
    loading: queryLoading,
  } = useFragmentOrFetchEmployeeDependant({
    id: dependantId as string,
    companyId,
  });
  const [update] = useUpdateDependantMutation();

  const title = useMemo(() => {
    if (dependant?.fullName) {
      return `Update ${dependant?.fullName}`;
    } else {
      return 'Update Dependant';
    }
  }, [dependant?.fullName]);

  const onSuccess = useCallback(
    (data: SingleExecutionResult<TUpdateDependantMutation>) => {
      if (data.errors) {
        for (const error of data.errors) {
          console.error(serializeError(error));
          toast.error(error.message);
        }
      } else {
        navigate(
          generatePath(`${EMPLOYEES_PATH}/${EMPLOYEE_PATH}/${DEPENDANTS_PATH}`, {
            employeeId: data.data?.updateDependant?.employeeId as string,
          }),
        );
      }
    },
    [navigate],
  );

  const onSubmitError = useCallback((error: ApolloError) => {
    if (error instanceof ApolloError) {
      for (const err of error.graphQLErrors) {
        console.error(serializeError(err));
        toast.error(err.message);
      }
    }
  }, []);

  const onSubmit = useCallback<TControlledFormProps<TUpdateDependantMutationInput>['onValidSubmit']>(
    (formData) => {
      if (dependant?.id && dependant.companyId && dependant._version) {
        return update({
          variables: {
            id: dependant.id,
            companyId: dependant.companyId,
            version: dependant._version,
            input: {
              ...formData,
            },
          },
        })
          .then(onSuccess)
          .catch(onSubmitError);
      }
    },
    [dependant?.id, dependant?.companyId, dependant?._version, update, onSuccess, onSubmitError],
  );

  if (queryError) return <ErrorPage error={queryError} />;
  if (queryLoading) return <LoadingPage />;

  return (
    <ModalForm
      onBackClick={onBackClick}
      closeModal={closeModal}
      controlledFormProps={{
        className: styles.form,
        fields: getEmployeeDependantFormFields({ company, styles }),
        formProps: {
          defaultValues: getEmployeeDependantDefaultValues(dependant),
          resolver: joiResolver(dependantSchema, {
            convert: true,
            abortEarly: false,
            stripUnknown: false,
          }),
        },
        onValidSubmit: onSubmit,
      }}
      headline={title}
      supportingText='Updates might take up to 4 weeks to be effective since gifts might already be in production or in transit.'
    />
  );
};
