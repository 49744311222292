import {
  OnCompanyUserChangedDocument,
  TListUsersQuery,
  TListUsersQueryVariables,
  TOnCompanyUserChangedSubscriptionVariables,
  useListUsersQuery,
} from '../../generated/graphql';
import { useQueryAll } from '../shared/useQueryAll';

export type TListUser = Exclude<TListUsersQuery['listUsers']['items'][number], null | undefined>;

export const useQueryAllUsers = (variables: TListUsersQueryVariables) => {
  return useQueryAll<TListUsersQuery, TListUsersQueryVariables, TOnCompanyUserChangedSubscriptionVariables>({
    subscriptionDoc: OnCompanyUserChangedDocument,
    subscriptionVariables: {
      companyId: variables.companyId,
    },
    useQuery: useListUsersQuery,
    variables,
  });
};
