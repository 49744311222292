import { Link } from '@chocolate-soup-inc/cs-frontend-components';
import { TGiftShipmentFieldsFragment } from '../../generated/graphql';
import styles from './StatusCell.module.scss';
import clsx from 'clsx';
import tableStyles from '../../components/TablePage/TablePage.module.scss';

export const getShipmentFinalStatus = (shipment?: TGiftShipmentFieldsFragment | null) => {
  const { status } = shipment || {};

  switch (status) {
    case undefined:
    case 'packaging':
      return 'Assembling';
    case 'readyToShip':
    case 'printingLabel':
      return 'Awaiting Shipment';
    case 'labelPrinted':
      return 'Shipping Label Printed';
    case 'shipped':
      return 'In Transit';
    case 'delivered':
      return 'Delivered';
    case 'readyForPickup':
      return 'Available for Pickup';
    case 'returned':
      return 'Returned';
    default:
      return status;
  }
};

export const getShipmentFinalColor = (shipment?: TGiftShipmentFieldsFragment | null) => {
  const { status } = shipment || {};

  switch (status) {
    case 'packaging':
    case 'readyToShip':
    case 'printingLabel':
    case undefined:
      return '#92979D';
    case 'labelPrinted':
    case 'shipped':
      return '#3E66FB';
    case 'delivered':
      return '#66CA65';
    case 'readyForPickup':
      return '#F3BB1C';
    case 'returned':
      return '#F03738';
  }
};

export const getTrackingLink = (shipment: TGiftShipmentFieldsFragment) => {
  const { trackingNumber, label } = shipment;
  const { carrier = undefined } = label || {};

  switch (carrier) {
    case 'canada_post':
      return `https://www.canadapost-postescanada.ca/track-reperage/en#/search?searchFor=${trackingNumber}`;
    case 'purolator_ca':
      return `https://www.purolator.com/en/shipping/tracker?pin=${trackingNumber}`;
    case 'dhl_express_canada':
      return `https://www.dhl.com/br-en/home/tracking/tracking-express.html?submit=1&tracking-id=${trackingNumber}`;
    default:
      break;
  }
};

export const TrackingLink = (shipment: TGiftShipmentFieldsFragment) => {
  return (
    <div className={clsx(tableStyles.tableSpacePrimary)}>
      {shipment.trackingNumber ? (
        <Link
          href={getTrackingLink(shipment)}
          label={shipment.trackingNumber}
          icon={true}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();

            window.open(getTrackingLink(shipment));
          }}
        />
      ) : (
        '-'
      )}
    </div>
  );
};

export const StatusCell = (shipment?: TGiftShipmentFieldsFragment | null) => {
  const text = getShipmentFinalStatus(shipment);
  const color = getShipmentFinalColor(shipment);

  return (
    <div className={clsx(styles.statusContainer, tableStyles.tableSpacePrimary)}>
      <span className={styles.statusIndicator} style={{ backgroundColor: color }} />
      <span className={styles.statusText}>{text}</span>
    </div>
  );
};
