import { generatePath } from 'react-router-dom';
import { NavigationTabs } from '@chocolate-soup-inc/cs-frontend-components';
import styles from './AdministrationTabs.module.scss';

import clsx from 'clsx';
import { COMPANY_SETTINGS_PATH, OFFICES_PATH, USERS_PATH } from '../../../routes/paths';
import { useLayoutContext } from '../../shared/Layout';

export const AdministrationTabs = () => {
  const { className, titleClassName } = useLayoutContext();

  return (
    <div className={clsx(className, styles.tabsOuter)}>
      <h1 className={clsx(titleClassName, styles.tablePageTitle)}>Administration</h1>
      <NavigationTabs
        className={styles.tabs}
        options={[
          {
            label: 'Company Settings',
            leadingIcon: 'business_center',
            path: generatePath(COMPANY_SETTINGS_PATH),
          },
          {
            label: 'Offices',
            leadingIcon: 'domain',
            path: generatePath(OFFICES_PATH),
          },
          {
            label: 'Manage Users',
            leadingIcon: 'manage_accounts',
            path: generatePath(USERS_PATH),
          },
        ]}
      />
    </div>
  );
};
