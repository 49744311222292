import { getDateWithTimezoneFixed } from '@chocolate-soup-inc/cs-api-consumer-utils';
import Joi from 'joi';
import _ from 'lodash';
import { clearableField } from '../shared/clearableField';
import { AWSDateSchema } from '../shared/utils';
import { TGetDependant } from './shared';

export const dependantSchema = Joi.object()
  .unknown(false)
  .keys({
    id: Joi.forbidden(),
    companyId: Joi.forbidden(),
    employeeId: Joi.forbidden(),
    type: Joi.string().trim().valid('significantOther', 'child', 'pet'),
    petType: Joi.string()
      .trim()
      .lowercase()
      .when('type', {
        is: 'pet',
        then: Joi.required(),
        otherwise: Joi.any().empty(Joi.any()).strip(),
      }),
    firstName: Joi.string().trim().required(),
    preferredFirstName: clearableField(Joi.string().trim()),
    lastName: clearableField(
      Joi.string()
        .trim()
        .when('type', {
          is: 'pet',
          then: Joi.any().empty(Joi.any()).strip(),
        }),
    ),
    birthDate: clearableField(AWSDateSchema),
    foodPreferences: clearableField(Joi.string().trim()),
    donateBirthdayGift: Joi.boolean().empty(Joi.valid(null, '')).default(false),
  });

export const getEmployeeDependantSchema = () => {
  return dependantSchema;
};

export const getEmployeeDependantDefaultValues = (dependant?: TGetDependant | null) => {
  return {
    ..._.pick(dependant || {}, 'firstName', 'lastName', 'type', 'petType', 'foodPreferences'),
    birthDate: getDateWithTimezoneFixed(dependant?.birthDate),
  };
};
