import { Sidebar as SidebarComponent } from '@chocolate-soup-inc/cs-frontend-components';
import {
  ADMINISTRATION_PATH,
  DELIVERIES_PATH,
  EMPLOYEES_PATH,
  HISTORY_PATH,
  NEW_PATH,
  OVERVIEW_PATH,
} from '../../routes/paths';
import { generatePath } from 'react-router-dom';
import { usePrivateCompanyContext } from '../../routes/outlets/PrivateCompanyOutlet';

import styles from './Sidebar.module.scss';

export const Sidebar = () => {
  const { name } = usePrivateCompanyContext();
  document.title = `${name} | Client Portal | Chocolate Soup`;

  return (
    <SidebarComponent
      className={styles.sidebar}
      sections={[
        {
          items: [
            {
              icon: 'group',
              label: 'Employees',
              path: EMPLOYEES_PATH,
            },
            {
              icon: 'add_circle',
              label: 'Add new',
              path: generatePath(`${EMPLOYEES_PATH}/${NEW_PATH}`),
              indent: 1,
            },
            {
              icon: 'local_shipping',
              label: 'Delivery',
              path: DELIVERIES_PATH,
            },
            {
              icon: 'insights',
              label: 'Overview',
              path: generatePath(`${DELIVERIES_PATH}/${OVERVIEW_PATH}`),
              indent: 1,
            },
            {
              icon: 'list_alt',
              label: 'Shipment History',
              indent: 1,
              path: generatePath(`${DELIVERIES_PATH}/${HISTORY_PATH}`),
            },
            {
              icon: 'settings',
              label: 'Administration',
              path: ADMINISTRATION_PATH,
            },
          ],
        },
        {
          title: 'Custom Orders (Coming Soon!)',
          items: [
            {
              className: styles.disabled,
              disabled: true,
              icon: 'favorite',
              label: 'Care & Recognition',
              path: 'care-and-recognition',
            },
            {
              className: styles.disabled,
              disabled: true,
              icon: 'folder',
              label: 'Custom Bricks',
              path: 'custom-bricks',
            },
            {
              className: styles.disabled,
              disabled: true,
              icon: 'face_retouching_natural',
              label: 'Life Events',
              path: 'life-events',
            },
          ],
        },
      ]}
      title={name}
    />
  );
};
