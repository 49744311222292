import { ApolloClient, useApolloClient } from '@apollo/client';
import { subscribe as subscribeBase, TConnectionItem } from '@chocolate-soup-inc/cs-api-consumer-utils';
import { useEffect } from 'react';
import { serializeError } from 'serialize-error';
import { onSubscriptionData } from '../../config/apollo/cache';
import {
  GetGiftDocument,
  OnCompanyEventGiftCreatedDocument,
  OnCompanyGiftChangedDocument,
  OnCompanyGiftRemovedFromGroupDocument,
  OnCompanyShipmentRecreatedDocument,
  TGetGiftQuery,
  TGetGiftQueryVariables,
  TListEmployeeAndDependatsGiftsQuery,
  TListEmployeeAndDependatsGiftsQueryVariables,
  TOnCompanyEventGiftCreatedSubscription,
  TOnCompanyGiftChangedSubscription,
  TOnCompanyGiftChangedSubscriptionVariables,
  TOnCompanyGiftRemovedFromGroupSubscription,
  TOnCompanyShipmentRecreatedSubscription,
  useListEmployeeAndDependatsGiftsQuery,
} from '../../generated/graphql';
import { useSubscribeToCompanyDependantChanged } from '../dependant/shared';
import { useSubscribeToCompanyEmployeeChanged } from '../employee/shared';
import { useQueryAll } from '../shared/useQueryAll';
import { useSubscribeToCompanyShipmentChangedWithNoGifts } from '../shipment/shared';

type TOnGiftSubscriptionDataProps = {
  client: ApolloClient<object>;
  data?: TOnCompanyGiftChangedSubscription;
  vars?: TOnCompanyGiftChangedSubscriptionVariables;
};

const onGiftSubscriptionData = (props: TOnGiftSubscriptionDataProps) => {
  const { client, data, vars } = props;

  const { companyId, employeeId, id, _deleted } = data?.onCompanyGiftChanged || {};

  if (_deleted) {
    return onSubscriptionData(data as Record<string, any>, vars);
  } else if (companyId && id) {
    client
      .query<TGetGiftQuery, TGetGiftQueryVariables>({
        query: GetGiftDocument,
        variables: {
          id,
          companyId,
        },
      })
      .then((response) => {
        const giftData = response?.data.getGift as TConnectionItem;

        if (giftData) {
          const subscriptionData = {
            onCompanyGiftChanged: giftData,
          };

          // UPDATE QUERIES THAT DEPENDS ON EMPLOYEE
          onSubscriptionData(subscriptionData, {
            employeeId,
            companyId,
          });

          // UPDATE QUERIES THAT DO NOT DEPEND ON EMPLOYEE
          onSubscriptionData(subscriptionData, {
            companyId,
          });
        }
      })
      .catch((error) => {
        console.error(serializeError(error));
      });
  }
};

export const useSubscribeToCompanyGiftChangedWithNoShipmentSubscription = (
  variables: TOnCompanyGiftChangedSubscriptionVariables,
) => {
  const client = useApolloClient();
  // SHOULD SUBSCRIBE TO CHANGES TO EMPLOYEES, DEPENDANTS (POSSIBLE RECIPIENTS)
  useSubscribeToCompanyEmployeeChanged({
    companyId: variables.companyId,
  });

  useSubscribeToCompanyDependantChanged({
    companyId: variables.companyId,
  });

  useEffect(() => {
    subscribeBase({
      client,
      query: OnCompanyGiftChangedDocument,
      variables,
      onSubscriptionData: (data, vars) => {
        onGiftSubscriptionData({
          client,
          data: data as TOnCompanyGiftChangedSubscription,
          vars: vars as TOnCompanyGiftChangedSubscriptionVariables,
        });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, JSON.stringify(variables)]);

  useEffect(() => {
    subscribeBase({
      client,
      query: OnCompanyEventGiftCreatedDocument,
      variables,
      onSubscriptionData: (data, vars) => {
        const giftData = (data as TOnCompanyEventGiftCreatedSubscription)?.onCompanyEventGiftCreated?.gift;

        if (giftData) {
          onGiftSubscriptionData({
            client,
            data: {
              onCompanyGiftChanged: giftData,
            } as TOnCompanyGiftChangedSubscription,
            vars: vars as TOnCompanyGiftChangedSubscriptionVariables,
          });
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, JSON.stringify(variables)]);

  useEffect(() => {
    subscribeBase({
      client,
      query: OnCompanyGiftRemovedFromGroupDocument,
      variables,
      onSubscriptionData: (data, vars) => {
        const items = (data as TOnCompanyGiftRemovedFromGroupSubscription)?.onCompanyGiftRemovedFromGroup?.items;

        if (items && Array.isArray(items) && items.length > 0) {
          items.forEach((item) => {
            onGiftSubscriptionData({
              client,
              data: {
                onCompanyGiftChanged: item,
              } as TOnCompanyGiftChangedSubscription,
              vars: vars as TOnCompanyGiftChangedSubscriptionVariables,
            });
          });
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, JSON.stringify(variables)]);

  useEffect(() => {
    subscribeBase({
      client,
      query: OnCompanyShipmentRecreatedDocument,
      variables,
      onSubscriptionData: (data, vars) => {
        const items = (data as TOnCompanyShipmentRecreatedSubscription)?.onCompanyShipmentRecreated?.items;

        if (items && Array.isArray(items) && items.length > 0) {
          items.forEach((item) => {
            onGiftSubscriptionData({
              client,
              data: {
                onCompanyGiftChanged: item,
              } as TOnCompanyGiftChangedSubscription,
              vars: vars as TOnCompanyGiftChangedSubscriptionVariables,
            });
          });
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, JSON.stringify(variables)]);
};

export const useSubscribeToCompanyGiftChanged = (variables: TOnCompanyGiftChangedSubscriptionVariables) => {
  useSubscribeToCompanyShipmentChangedWithNoGifts({
    companyId: variables.companyId,
  });

  useSubscribeToCompanyGiftChangedWithNoShipmentSubscription(variables);
};

export type TListGift = Exclude<
  TListEmployeeAndDependatsGiftsQuery['listEmployeeAndDependatsGifts']['items'][number],
  null | undefined
>;

export const useQueryAllEmployeeAndDependantsGifts = (variables: TListEmployeeAndDependatsGiftsQueryVariables) => {
  useSubscribeToCompanyGiftChanged({
    companyId: variables.companyId,
  });

  const { data, error, loading } = useQueryAll<
    TListEmployeeAndDependatsGiftsQuery,
    TListEmployeeAndDependatsGiftsQueryVariables
  >({
    useQuery: useListEmployeeAndDependatsGiftsQuery,
    variables,
  });

  return {
    data,
    error,
    loading,
  };
};
