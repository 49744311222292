import {
  Select,
  TextField,
  TSelectBaseProps,
  TSingleSelectProps,
  TTextFieldInputProps,
} from '@chocolate-soup-inc/cs-frontend-components';
import clsx from 'clsx';
import styles from './Filters.module.scss';

type TSelectFilterSharedProps = {
  disabled?: boolean;
  includeEmptyOption?: boolean;
  label?: string;
  name?: string;
  inputOptions?: Omit<
    TSelectBaseProps,
    | 'disabled'
    | 'includeEmptyOption'
    | 'label'
    | 'multiple'
    | 'name'
    | 'onChange'
    | 'options'
    | 'options'
    | 'optionsPlacement'
    | 'value'
    | 'variant'
  >;
};

type TSingleSelectFilterProps = TSelectFilterSharedProps & {
  onChange: TSingleSelectProps['onChange'];
  options: TSingleSelectProps['options'];
  value: TSingleSelectProps['value'];
};

export const SingleSelectFilter = (props: TSingleSelectFilterProps) => {
  const {
    disabled,
    includeEmptyOption = false,
    inputOptions = {},
    label,
    name = `${label}-filter`,
    onChange,
    options,
    value,
  } = props;

  return (
    <div className={clsx(styles.filter, styles.singleSelect)}>
      <Select
        className={styles.singleSelect}
        disabled={disabled}
        includeEmptyOption={includeEmptyOption}
        label={label}
        multiple={false}
        name={name}
        onChange={onChange}
        options={options}
        optionsPlacement='bottom'
        value={value}
        variant='outlined'
        {...inputOptions}
      />
    </div>
  );
};

export type TTextFilterProps = Partial<
  Pick<TTextFieldInputProps, 'className' | 'disabled' | 'label' | 'leadingIcon' | 'name' | 'onChange' | 'value'>
> & {
  inputOptions?: Omit<
    TTextFieldInputProps,
    'className' | 'disabled' | 'label' | 'leadingIcon' | 'multiline' | 'name' | 'onChange' | 'value' | 'variant'
  >;
};

export const TextFilter = (props: TTextFilterProps) => {
  const {
    className,
    disabled,
    inputOptions = {},
    label,
    leadingIcon,
    name = `${label}-filter`,
    onChange,
    value,
  } = props;

  return (
    <div className={clsx(className, styles.filter, styles.text)}>
      <TextField
        className={clsx(styles.textField)}
        disabled={disabled}
        label={label}
        leadingIcon={leadingIcon}
        multiline={false}
        name={name}
        onChange={onChange}
        value={value}
        variant='outlined'
        {...inputOptions}
      />
    </div>
  );
};

export type TFilterProps =
  | (TSingleSelectFilterProps & {
      type: 'singleSelect';
    })
  | (TTextFilterProps & {
      type: 'textInput';
    });

export type TFiltersProps = {
  filters: TFilterProps[];
};

export const Filters = (props: TFiltersProps) => {
  const { filters } = props;

  return (
    <div className={styles.filters}>
      {filters.map((filter) => {
        if (filter.type === 'singleSelect') {
          return <SingleSelectFilter key={filter.name || filter.label} {...filter} />;
        } else if (filter.type === 'textInput') {
          return <TextFilter key={filter.name || filter.label} {...filter} />;
        }
      })}
    </div>
  );
};
