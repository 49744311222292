import Joi from 'joi';

export const customJoiDate = Joi.extend((joi) => {
  return {
    type: 'customJoiDate',
    base: joi.date(),
    messages: {
      'awsDate.notDate': 'The {{#label}} is not a valid date.',
      'date.pastInvalid': 'The {{#label}} cannot be in the past.',
      'date.futureInvalid': 'The {{#label}} cannot be in the future.',
    },
    validate: (value, helpers) => {
      // Base validation regardless of the rules applied
      const errors: Joi.ErrorReport[] = [];
      if (value != null && !(value instanceof Date)) {
        errors.push(helpers.error('awsDate.notDate'));
      }

      return {
        errors,
        value,
      };
    },
    rules: {
      AWSDate: {
        method() {
          return this.$_addRule('AWSDate');
        },
        validate(value) {
          return value.toISOString().split('T')[0];
        },
      },
      AWSDateTime: {
        method() {
          return this.$_addRule('AWSDateTime');
        },
        validate(value) {
          return value.toISOString();
        },
      },
      pastInvalid: {
        method() {
          return this.$_addRule('pastInvalid');
        },
        validate(value, helpers) {
          if (value != null) {
            const today = new Date();
            today.setUTCHours(0, 0, 0, 0);

            const fromDate = new Date(value);
            fromDate.setUTCHours(0, 0, 0, 0);

            if (fromDate.getTime() < today.getTime()) {
              return helpers.error('date.pastInvalid');
            }
          }

          return value;
        },
      },
      futureInvalid: {
        method() {
          return this.$_addRule('futureInvalid');
        },
        validate(value, helpers) {
          if (value != null) {
            const today = new Date();
            today.setUTCHours(0, 0, 0, 0);

            const fromDate = new Date(value);
            fromDate.setUTCHours(0, 0, 0, 0);

            if (fromDate.getTime() > today.getTime()) {
              return helpers.error('date.futureInvalid');
            }
          }

          return value;
        },
      },
    },
  };
});

export const AWSDateSchema = Joi.date()
  .empty(Joi.valid(null, ''))
  .custom((value) => {
    if (value != null) {
      if (value instanceof Date) {
        return value.toISOString().split('T')[0];
      } else {
        throw new Error('Value is not an instance of Date.');
      }
    }

    return value;
  })
  .messages({
    'date.pastInvalid': 'The {{#label}} cannot be in the past.',
    'date.futureInvalid': 'The {{#label}} cannot be in the future.',
  });

export const AWSDateTimeSchema = Joi.date()
  .empty(Joi.valid(null, ''))
  .custom((value, helpers) => {
    if (value != null) {
      if (value instanceof Date) {
        return value.toISOString();
      } else {
        return helpers.error('awsDate.notDate');
      }
    }

    return value;
  })
  .messages({
    'awsDate.notDate': 'The {{#label}} is not a valid date.',
  });
