import { ErrorPage, LoadingPage } from '@chocolate-soup-inc/cs-frontend-components';
import { createContext, useContext, useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { configAuth } from '../../config/auth';
import {
  TGetCompanyBySubdomainQuery,
  TPublicCompanyFieldsFragment,
  useGetCompanyBySubdomainQuery,
} from '../../generated/graphql';

const PublicCompanyContext = createContext<{
  company?: TPublicCompanyFieldsFragment;
}>({});

const getSubdomain = () => {
  if (window.location.host.includes('localhost')) {
    return process.env.REACT_APP_SUBDOMAIN || 'chocolatesoup';
  } else {
    return window.location.host.split('.')[0];
  }
};

const getAuthDomain = (
  configuration: Exclude<TGetCompanyBySubdomainQuery['getCompanyBySubdomain'], undefined | null>['configuration'],
) => {
  const parts = configuration?.authUserPoolDomainURL?.split('https://');

  if (parts?.length === 1) {
    return parts[0];
  } else {
    return parts?.slice(1).join('https://');
  }
};

export const PublicCompanyOutlet = () => {
  const subdomain = useMemo(() => {
    return getSubdomain();
  }, []);

  const {
    data,
    error: getCompanyBySubdomainError,
    loading,
  } = useGetCompanyBySubdomainQuery({
    fetchPolicy: 'cache-first',
    variables: {
      subdomain,
    },
  });

  useEffect(() => {
    const configuration = data?.getCompanyBySubdomain?.configuration;

    if (configuration) {
      configAuth({
        userPoolId: configuration.authUserPoolId as string,
        userPoolClientId: configuration.authUserPoolClientId as string,
        authDomain: getAuthDomain(configuration) as string,
      });
    }
  }, [data?.getCompanyBySubdomain?.configuration]);

  const error = useMemo<Error | undefined>(() => {
    if (data?.getCompanyBySubdomain?.subdomain && data?.getCompanyBySubdomain?.subdomain !== subdomain) {
      return new Error('Current user company is not the same as the subdomain company.');
    }
    return getCompanyBySubdomainError;
  }, [data?.getCompanyBySubdomain?.subdomain, getCompanyBySubdomainError, subdomain]);

  if (error) return <ErrorPage error={error} />;
  if (!data || loading) return <LoadingPage />;

  if (data.getCompanyBySubdomain == null) {
    throw new Error('Company for subdomain not found.');
  }

  return (
    <PublicCompanyContext.Provider
      value={{
        company: data.getCompanyBySubdomain,
      }}
    >
      <Outlet />
    </PublicCompanyContext.Provider>
  );
};

export const usePublicCompanyContext = () => {
  const context = useContext(PublicCompanyContext);

  if (context == null) throw new Error('usePublicCompanyContext must be used inside the PublicCompanyContext node.');

  if (context.company == null) throw new Error('Company for subdomain not found.');

  return context.company;
};
