import { MouseEventHandler, useCallback, useMemo, useState } from 'react';
import imgNormal from '../../../assets/1x/btn_google_signin_light_normal_web.png';
import imgDisabled from '../../../assets/1x/btn_google_signin_light_disabled_web.png';
import imgFocus from '../../../assets/1x/btn_google_signin_light_focus_web.png';
import imgPressed from '../../../assets/1x/btn_google_signin_light_pressed_web.png';
import styles from './LoginWithGoogleButton.module.scss';

type TLoginWithGoogleProps = {
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export const LoginWithGoogle = (props: TLoginWithGoogleProps) => {
  const { disabled, onClick } = props;

  const [hover, setHover] = useState<boolean>();
  const [focus, setFocus] = useState<boolean>();
  const [pressed, setPressed] = useState<boolean>();

  const buttonImage = useMemo(() => {
    if (disabled) return imgDisabled;
    if (pressed || hover) return imgPressed;
    if (focus) return imgFocus;
    return imgNormal;
  }, [disabled, focus, hover, pressed]);

  const onMouseEnter = useCallback(() => {
    setHover(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setHover(false);
    setPressed(false);
  }, []);

  const onMouseDown = useCallback(() => {
    setPressed(true);
  }, []);

  const onMouseUp = useCallback(() => {
    setPressed(false);
    setFocus(false);
  }, []);

  const onFocus = useCallback(() => {
    setFocus(true);
  }, []);

  const onBlur = useCallback(() => {
    setFocus(false);
  }, []);

  return (
    <button
      className={styles.button}
      onBlur={onBlur}
      onClick={onClick}
      onFocus={onFocus}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseUp={onMouseUp}
      type='button'
    >
      <img src={buttonImage} alt='Login with google' />
    </button>
  );
};
