import { ApolloClient, useApolloClient } from '@apollo/client';
import { subscribe as subscribeBase, TConnectionItem } from '@chocolate-soup-inc/cs-api-consumer-utils';
import { useEffect } from 'react';
import { serializeError } from 'serialize-error';
import { onSubscriptionData } from '../../config/apollo/cache';
import {
  GetMyCompanyDocument,
  OnCompanyChangedDocument,
  TGetMyCompanyQuery,
  TGetMyCompanyQueryVariables,
  TOnCompanyChangedSubscription,
  TOnCompanyChangedSubscriptionVariables,
} from '../../generated/graphql';

type TOnCompanySubscriptionDataProps = {
  client: ApolloClient<object>;
  data?: TOnCompanyChangedSubscription;
  vars?: TOnCompanyChangedSubscriptionVariables;
};

const onCompanySubscriptionData = (props: TOnCompanySubscriptionDataProps) => {
  const { client, data, vars } = props;

  const { id, _deleted } = data?.onCompanyChanged || {};

  if (_deleted) {
    return onSubscriptionData(data as Record<string, any>, vars);
  } else if (id) {
    client
      .query<TGetMyCompanyQuery, TGetMyCompanyQueryVariables>({
        query: GetMyCompanyDocument,
      })
      .then((response) => {
        const companyData = response?.data?.getMyCompany as TConnectionItem;

        if (companyData) {
          return onSubscriptionData(
            {
              onCompanyChanged: companyData,
            },
            vars,
          );
        }
      })
      .catch((error) => {
        console.error('Error', serializeError(error));
      });
  }
};

type TUseSubscribeToCompanyChangedProps = {
  id?: string;
};

export const useSubscribeToCompanyChanged = (variables: TUseSubscribeToCompanyChangedProps) => {
  const client = useApolloClient();

  useEffect(() => {
    if (variables?.id) {
      subscribeBase({
        client,
        query: OnCompanyChangedDocument,
        variables,
        onSubscriptionData: (data, vars) => {
          onCompanySubscriptionData({
            client,
            data: data as TOnCompanyChangedSubscription,
            vars: vars as TOnCompanyChangedSubscriptionVariables,
          });
        },
      });
    }
  }, [client, variables, variables?.id]);
};
