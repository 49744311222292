import { useCallback, useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { ADMINISTRATION_PATH, USERS_PATH } from '../../../routes/paths';
import styles from './UserForm.module.scss';
import { TCreateUserMutation, TCreateUserMutationInput, useCreateUserMutation } from '../../../generated/graphql';
import { ApolloError, SingleExecutionResult } from '@apollo/client';
import { ModalForm, TControlledFormProps } from '@chocolate-soup-inc/cs-frontend-components';
import { joiResolver } from '@hookform/resolvers/joi';
import { getUserSchema } from '../../../entities/user/schema';
import { getUserFormFields } from '../../../entities/user/formFields';
import { usePrivateCompanyContext } from '../../../routes/outlets/PrivateCompanyOutlet';
import { toast } from 'react-toastify';
import { serializeError } from 'serialize-error';

export const UserCreateForm = () => {
  const navigate = useNavigate();
  const company = usePrivateCompanyContext();

  const closeModal = useCallback(() => {
    navigate(generatePath(`${ADMINISTRATION_PATH}/${USERS_PATH}`));
  }, [navigate]);

  const [create] = useCreateUserMutation();

  const onSuccess = useCallback(
    (data: SingleExecutionResult<TCreateUserMutation>) => {
      if (data.errors) {
        for (const error of data.errors) {
          console.error(serializeError(error));
          toast.error(error.message);
        }
      } else {
        closeModal();
      }
    },
    [closeModal],
  );

  const onSubmitError = useCallback((error: ApolloError) => {
    if (error instanceof ApolloError) {
      for (const err of error.graphQLErrors) {
        console.error(serializeError(err));
        toast.error(err.message);
      }
    }
  }, []);

  const onSubmit = useCallback<TControlledFormProps<TCreateUserMutationInput>['onValidSubmit']>(
    (formData) => {
      if (company?.id) {
        return create({
          variables: {
            input: {
              ...formData,
              companyId: company.id,
            },
          },
        })
          .then(onSuccess)
          .catch(onSubmitError);
      }
    },
    [company, create, onSubmitError, onSuccess],
  );

  const userSchema = useMemo(() => {
    return getUserSchema({ company });
  }, [company]);

  return (
    <ModalForm
      closeModal={closeModal}
      controlledFormProps={{
        className: styles.form,
        fields: getUserFormFields(),
        formProps: {
          resolver: joiResolver(userSchema, {
            convert: true,
            abortEarly: false,
            stripUnknown: false,
          }),
        },
        onValidSubmit: onSubmit,
      }}
      headline='New User'
    />
  );
};
