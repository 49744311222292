import { DocumentNode, InMemoryCache, TypedDocumentNode } from '@apollo/client';

import {
  awsDateField,
  connectionField,
  onSubscriptionData as onSubscriptionDataBase,
  subscribe as subscribeBase,
  TSubscriptionMapping,
} from '@chocolate-soup-inc/cs-api-consumer-utils';
import {
  DependantFieldsFragmentDoc,
  EmployeeFieldsFragmentDoc,
  EventFieldsFragmentDoc,
  GiftFieldsFragmentDoc,
  ListCompanyUpcomingEventsDocument,
  ListEmployeeAndDependatsGiftsDocument,
  ListEmployeeDependantsDocument,
  ListCompanyEmployeesDocument,
  ListOfficesDocument,
  ListShipmentsDocument,
  ListUsersDocument,
  OfficeFieldsFragmentDoc,
  ShipmentFieldsFragmentDoc,
  TDependant,
  TEmployee,
  TGift,
  TOffice,
  TShipment,
  TUser,
  UserFieldsFragmentDoc,
  ListCompanyDependantsDocument,
} from '../../generated/graphql';
import { privateClient } from './api';

export const cache = new InMemoryCache({
  possibleTypes: {
    Recipient: ['Employee', 'Dependant'],
  },
  typePolicies: {
    Query: {
      fields: {
        listOffices: connectionField<TOffice>({ keyArgs: ['companyId'] }),
        listUsers: connectionField<TUser>({ keyArgs: ['companyId'] }),
        listCompanyEmployees: connectionField<TEmployee>({ keyArgs: ['companyId'] }),
        listEmployeeDependants: connectionField<TDependant>({ keyArgs: ['companyId', 'employeeId'] }),
        listEmployeeAndDependatsGifts: connectionField<TGift>({ keyArgs: ['companyId', 'employeeId'] }),
        listCompanyShipments: connectionField<TShipment>({ keyArgs: ['companyId'] }),
      },
    },
    User: {
      keyFields: ['email'],
    },
    Company: {
      fields: {
        startDate: awsDateField(),
        endDate: awsDateField(),
      },
    },
    Employee: {
      fields: {
        birthDate: awsDateField(),
        giftPausedFrom: awsDateField(),
        giftPausedTo: awsDateField(),
        hireDate: awsDateField(),
      },
    },
    Dependant: {
      fields: {
        birthDate: awsDateField(),
      },
    },
  },
});

const subscriptionMappings: Record<string, TSubscriptionMapping> = {
  onCompanyOfficeChanged: {
    listQueries: [ListOfficesDocument],
    fragment: OfficeFieldsFragmentDoc,
  },
  onCompanyUserChanged: {
    listQueries: [ListUsersDocument],
    fragment: UserFieldsFragmentDoc,
  },
  onCompanyEmployeeChanged: {
    listQueries: [ListCompanyEmployeesDocument],
    fragment: EmployeeFieldsFragmentDoc,
  },
  onCompanyDependantChanged: {
    listQueries: [ListEmployeeDependantsDocument, ListCompanyDependantsDocument],
    fragment: DependantFieldsFragmentDoc,
  },
  onCompanyGiftChanged: {
    listQueries: [ListEmployeeAndDependatsGiftsDocument],
    fragment: GiftFieldsFragmentDoc,
  },
  onCompanyShipmentChanged: {
    listQueries: [ListShipmentsDocument],
    fragment: ShipmentFieldsFragmentDoc,
  },
  onCompanyEventChanged: {
    listQueries: [ListCompanyUpcomingEventsDocument],
    fragment: EventFieldsFragmentDoc,
  },
};

export const onSubscriptionData = onSubscriptionDataBase({
  cache,
  subscriptionMappings,
});

export type TSubscribeData = {
  query: DocumentNode | TypedDocumentNode;
  variables: Record<string, any>;
};

export const subscribe = ({ query, variables }: TSubscribeData) => {
  return subscribeBase({
    client: privateClient,
    query,
    onSubscriptionData,
    variables,
  });
};
