import { Chip, TableInner } from '@chocolate-soup-inc/cs-frontend-components';
import clsx from 'clsx';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { TGetEmployee, useQueryAllEmployees } from '../../../entities/employee/shared';
import { TDeliveryMethod, TDependantType, TEmployee } from '../../../generated/graphql';
import { usePrivateCompanyContext } from '../../../routes/outlets/PrivateCompanyOutlet';
import { DEPENDANTS_PATH, EMPLOYEES_PATH, EMPLOYEE_ACCOUNT_PATH, EMPLOYEE_PATH } from '../../../routes/paths';
import { useLayoutContext } from '../../shared/Layout';
import {
  TGetCompanyDependant,
  TGetDependant,
  getReadableDependantType,
  useQueryAllCompanyDependants,
} from '../../../entities/dependant/shared';

import styles from './EmployeesMissingInfoAlert.module.scss';

type TEmployeesMissingInfoAlertProps = {
  className?: string;
  backTo?: string;
};

export const isDependant = (recipient: TEmployee | TGetCompanyDependant): recipient is TGetCompanyDependant => {
  return (recipient as TGetCompanyDependant).employeeId != null;
};
export const EmployeesMissingInfoAlert = (props: TEmployeesMissingInfoAlertProps) => {
  const { className, backTo } = props;

  const navigate = useNavigate();

  const {
    deliveryMethod,
    employeeBirthdayActivated,
    employeeWorkAnniversaryActivated,
    petBirthdayActivated,
    childBirthdayActivated,
    significantOtherBirthdayActivated,
    id: companyId,
  } = usePrivateCompanyContext();
  const { className: layotuClassName } = useLayoutContext();

  const { data: employeesData } = useQueryAllEmployees({
    companyId,
  });

  const employees = useMemo(() => {
    return _.compact(employeesData?.listCompanyEmployees?.items || []);
  }, [employeesData?.listCompanyEmployees?.items]);

  const { data: dependantsData } = useQueryAllCompanyDependants({
    companyId,
  });

  const employeesMissingInfoMap = useMemo(() => {
    return employees.reduce((agg, e) => {
      const missingInfo: (keyof TGetEmployee)[] = [];
      const incompleteInfo: (keyof TGetEmployee)[] = [];

      if (deliveryMethod === TDeliveryMethod.Home && (_.isEmpty(e.address) || e.address?.missingInfo)) {
        missingInfo.push('address');
      }

      if (deliveryMethod !== TDeliveryMethod.Office && e.address?.missingInfo) {
        incompleteInfo.push('address');
      }

      if (employeeBirthdayActivated && (e.birthDate == null || e.birthDate === '')) {
        missingInfo.push('birthDate');
      }

      if (employeeWorkAnniversaryActivated && (e.hireDate == null || e.hireDate === '')) {
        missingInfo.push('hireDate');
      }

      if (missingInfo.length > 0 || incompleteInfo.length > 0) {
        agg[e.id] = {
          missingInfo,
          incompleteInfo,
        };
      }

      return agg;
    }, {} as Record<string, { missingInfo: (keyof TGetEmployee)[]; incompleteInfo: (keyof TGetEmployee)[] }>);
  }, [employees, deliveryMethod, employeeBirthdayActivated, employeeWorkAnniversaryActivated]);

  const employeesMissingInfo = useMemo(() => {
    return employees.filter((e) => Object.keys(employeesMissingInfoMap).includes(e.id)) as TEmployee[];
  }, [employees, employeesMissingInfoMap]);

  const dependantsMissingInfoMap = useMemo(() => {
    return _.compact(dependantsData?.listCompanyDependants?.items || []).reduce((agg, d) => {
      const missingInfo: (keyof TGetCompanyDependant)[] = [];

      if (
        (d.type === TDependantType.Pet && !petBirthdayActivated) ||
        (d.type === TDependantType.Child && !childBirthdayActivated) ||
        (d.type === TDependantType.SignificantOther && !significantOtherBirthdayActivated)
      ) {
        return agg;
      }

      if (d.birthDate == null || d.birthDate === '') {
        missingInfo.push('birthDate');
      }

      if (missingInfo.length > 0) {
        agg[d.id] = missingInfo;
      }

      return agg;
    }, {} as Record<string, (keyof TGetDependant)[]>);
  }, [
    childBirthdayActivated,
    dependantsData?.listCompanyDependants?.items,
    petBirthdayActivated,
    significantOtherBirthdayActivated,
  ]);

  const dependantsMissingInfo: TGetCompanyDependant[] = useMemo(() => {
    return _.compact(dependantsData?.listCompanyDependants?.items || []).filter((d) =>
      Object.keys(dependantsMissingInfoMap).includes(d.id),
    ) as TGetCompanyDependant[];
  }, [dependantsData?.listCompanyDependants?.items, dependantsMissingInfoMap]);

  const getRowId = useCallback((target: TEmployee | TGetCompanyDependant) => {
    return target.id;
  }, []);

  const onEmployeeRowClick = useCallback(
    (id: string) => {
      navigate(
        generatePath(`${EMPLOYEES_PATH}/${EMPLOYEE_PATH}/${EMPLOYEE_ACCOUNT_PATH}`, {
          employeeId: id,
        }),
        {
          state: {
            backTo,
          },
        },
      );
    },
    [navigate, backTo],
  );

  const onDependantRowClick = useCallback(
    (id: string) => {
      navigate(
        generatePath(`${EMPLOYEES_PATH}/${EMPLOYEE_PATH}/${DEPENDANTS_PATH}`, {
          employeeId: id,
        }),
        {
          state: {
            backTo,
          },
        },
      );
    },
    [navigate, backTo],
  );

  if (employeesMissingInfo.length === 0 && dependantsMissingInfo.length === 0) {
    return null;
  }

  return (
    <div className={clsx(layotuClassName, className, styles.missingAddressAlert)}>
      <div className={clsx(styles.missingAddressAlertContent)}>
        {(employeesMissingInfo.length > 0 || dependantsMissingInfo.length > 0) && (
          <>
            <h2 className={styles.title}>DATA ISSUES</h2>
            <p className={styles.supportText}>
              {`Please ensure to update the following information as soon as possible. Missing or inaccurate data can lead to missed or delayed milestone gifts.`}
            </p>
            <TableInner<TEmployee | TGetCompanyDependant>
              data={(employeesMissingInfo as (TEmployee | TGetCompanyDependant)[]).concat(dependantsMissingInfo)}
              expandable={false}
              fixedHeader={false}
              hiddenHeader={true}
              getRowId={getRowId}
              hoverableRows={true}
              virtual={false}
              onRowClick={(row) => {
                if (isDependant(row.original)) {
                  onDependantRowClick(row.original.employeeId);
                } else {
                  onEmployeeRowClick(row.original.id);
                }
              }}
              columns={[
                {
                  header: 'Full Name',
                  cell: ({ cell }) => {
                    const row = cell.row.original;
                    let missingInfo: string[] = [];
                    let incompleteInfo: string[] = [];

                    if (isDependant(row)) {
                      missingInfo = dependantsMissingInfoMap[row.id];
                    } else {
                      ({ missingInfo, incompleteInfo } = employeesMissingInfoMap[row.id]);
                    }

                    return (
                      <div className={styles.rowNameColumn}>
                        <span>{`${row.fullName}${
                          isDependant(row)
                            ? ` (${getReadableDependantType(row.type)} of ${
                                employees.find((e) => e.id === row.employeeId)?.fullName
                              })`
                            : ''
                        }`}</span>
                        {missingInfo?.includes('address') && (
                          <Chip
                            className={styles.errorChip}
                            label='Missing address'
                            readonly={true}
                            selected={true}
                            variant='suggestion'
                          />
                        )}
                        {incompleteInfo?.includes('address') && (
                          <Chip
                            className={styles.errorChip}
                            label='Address incomplete'
                            readonly={true}
                            selected={true}
                            variant='suggestion'
                          />
                        )}
                        {missingInfo?.includes('hireDate') && (
                          <Chip
                            className={styles.errorChip}
                            label='Missing hire date'
                            readonly={true}
                            selected={true}
                            variant='suggestion'
                          />
                        )}
                        {missingInfo?.includes('birthDate') && (
                          <Chip
                            className={styles.errorChip}
                            label='Missing birth date'
                            readonly={true}
                            selected={true}
                            variant='suggestion'
                          />
                        )}
                      </div>
                    );
                  },
                },
              ]}
            />
          </>
        )}
        {/* {dependantsMissingInfo.length > 0 && (
          <>
            <h2 className={styles.title}>Update dependants information.</h2>
            <p className={styles.supportText}>
              {`Update the following dependants information, otherwise they could have their gift delivery affected and we'll miss
          some important dates.`}
            </p>
            <TableInner<TGetCompanyDependant>
              data={dependantsMissingInfo}
              expandable={false}
              fixedHeader={false}
              getRowId={getRowId}
              hoverableRows={true}
              virtual={false}
              onRowClick={(row) => {
                onDependantRowClick(row.original.employeeId);
              }}
              columns={[
                {
                  header: 'Full Name',
                  cell: ({ cell }) => {
                    const missingInfo = dependantsMissingInfoMap[cell.row.original.id];

                    return (
                      <div className={styles.rowNameColumn}>
                        <span>{cell.row.original.fullName}</span>
                        {missingInfo?.includes('birthDate') && (
                          <Chip
                            className={styles.errorChip}
                            label='Missing birth date'
                            readonly={true}
                            selected={true}
                            variant='suggestion'
                          />
                        )}
                      </div>
                    );
                  },
                },
                {
                  header: 'Dependant Type',
                  cell: ({ cell }) => {
                    return getReadableDependantType(cell.row.original.type);
                  },
                },
                {
                  header: 'Employee Full Name',
                  cell: ({ cell }) => {
                    const { employeeId } = cell.row.original;
                    const employee = employees.find((e) => e.id === employeeId);

                    return employee?.fullName;
                  },
                },
              ]}
            />
          </>
        )} */}
      </div>
    </div>
  );
};
