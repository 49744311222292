import { ApolloClient, useApolloClient } from '@apollo/client';
import {
  getFragmentName,
  subscribe as subscribeBase,
  TConnectionItem,
} from '@chocolate-soup-inc/cs-api-consumer-utils';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { serializeError } from 'serialize-error';
import { onSubscriptionData } from '../../config/apollo/cache';
import {
  GetShipmentDocument,
  OnCompanyShipmentChangedDocument,
  ShipmentFieldsFragmentDoc,
  TGetShipmentQuery,
  TGetShipmentQueryVariables,
  TListShipmentsQuery,
  TListShipmentsQueryVariables,
  TOnCompanyShipmentChangedSubscription,
  TOnCompanyShipmentChangedSubscriptionVariables,
  useGetShipmentLazyQuery,
  useListShipmentsQuery,
} from '../../generated/graphql';
import { useSubscribeToCompanyChanged } from '../company/shared';
import { useSubscribeToCompanyEmployeeChanged } from '../employee/shared';
import { useSubscribeToCompanyGiftChangedWithNoShipmentSubscription } from '../gift/shared';
import { useSubscribeToCompanyOfficeChanged } from '../office/shared';
import { useFragmentOrFetch } from '../shared/useFragmentOrFetch';
import { useQueryAll } from '../shared/useQueryAll';

type TOnShipmentSubscriptionDataProps = {
  client: ApolloClient<object>;
  data?: TOnCompanyShipmentChangedSubscription;
  vars?: TOnCompanyShipmentChangedSubscriptionVariables;
};

const onShipmentSubscriptionData = (props: TOnShipmentSubscriptionDataProps) => {
  const { client, data, vars } = props;

  const { companyId, id, _deleted } = data?.onCompanyShipmentChanged || {};

  if (_deleted) {
    return onSubscriptionData(data as Record<string, any>, vars);
  } else if (companyId && id) {
    client
      .query<TGetShipmentQuery, TGetShipmentQueryVariables>({
        query: GetShipmentDocument,
        variables: {
          id,
          companyId,
        },
      })
      .then((response) => {
        const shipmentData = response?.data?.getShipment as TConnectionItem;

        if (shipmentData) {
          return onSubscriptionData(
            {
              onCompanyShipmentChanged: shipmentData,
            },
            vars,
          );
        }
      })
      .catch((error) => {
        console.error('Error', serializeError(error));
      });
  }
};

export const useSubscribeToCompanyShipmentChangedWithNoGifts = (
  variables: TOnCompanyShipmentChangedSubscriptionVariables,
) => {
  const client = useApolloClient();

  // SUBSCRIBE TO COMPANIES, OFFICES AND EMPLOYEES BECAUSE THEIR ADDRESS COULD CHANGE AND WE NEED TO CHANGE THE ADDRESS FROM THE SHIPMENT IN THOSE SITUATIONS.

  useSubscribeToCompanyChanged({
    id: variables.companyId,
  });

  useSubscribeToCompanyOfficeChanged({
    companyId: variables.companyId,
  });

  useSubscribeToCompanyEmployeeChanged({
    companyId: variables.companyId,
  });

  useEffect(() => {
    subscribeBase({
      client,
      query: OnCompanyShipmentChangedDocument,
      variables,
      onSubscriptionData: (data, vars) => {
        onShipmentSubscriptionData({
          client,
          data: data as TOnCompanyShipmentChangedSubscription,
          vars: vars as TOnCompanyShipmentChangedSubscriptionVariables,
        });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, JSON.stringify(variables)]);
};

export const useSubscribeToCompanyShipmentChanged = (variables: TOnCompanyShipmentChangedSubscriptionVariables) => {
  // SUBSCRIBE TO GIFTS BECAUSE ONE OF ITS GIFTS MIGHT CHANGE
  useSubscribeToCompanyGiftChangedWithNoShipmentSubscription({
    companyId: variables.companyId,
  });

  useSubscribeToCompanyShipmentChangedWithNoGifts(variables);
};

export type TGetShipment = Exclude<TGetShipmentQuery['getShipment'], undefined | null>;

export const useFragmentOrFetchShipment = (variables: TGetShipmentQueryVariables) => {
  useSubscribeToCompanyShipmentChanged({
    companyId: variables.companyId,
  });

  return useFragmentOrFetch<TGetShipment, TGetShipmentQuery, TGetShipmentQueryVariables>({
    fragmentDoc: ShipmentFieldsFragmentDoc,
    fragmentName: getFragmentName(ShipmentFieldsFragmentDoc),
    useLazyQuery: useGetShipmentLazyQuery,
    variables,
    __typename: 'Shipment',
  });
};

export type TListShipment = Exclude<TListShipmentsQuery['listCompanyShipments']['items'][number], null | undefined>;

export const useQueryAllShipments = (variables: TListShipmentsQueryVariables) => {
  useSubscribeToCompanyShipmentChanged({
    companyId: variables.companyId,
  });

  const { data, error, loading } = useQueryAll<TListShipmentsQuery, TListShipmentsQueryVariables>({
    useQuery: useListShipmentsQuery,
    variables,
  });

  const shipments = useMemo(() => {
    return _.compact(data?.listCompanyShipments.items || []);
  }, [data?.listCompanyShipments.items]);

  return {
    data: shipments,
    error,
    loading,
  };
};

export const sortShipments = (a: TListShipment, b: TListShipment) => {
  return (
    new Date(b.actualShippingDate || b.shippingDate).getTime() -
    new Date(a.actualShippingDate || a.shippingDate).getTime()
  );
};
