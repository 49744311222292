import { ErrorPage, LoadingPage, useAuthContext } from '@chocolate-soup-inc/cs-frontend-components';
import { createContext, useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { TPrivateCompanyFieldsFragment, useGetMyCompanyLazyQuery } from '../../generated/graphql';
import { useSubscribeToCompanyChanged } from '../../entities/company/shared';

const PrivateCompanyContext = createContext<{
  company?: TPrivateCompanyFieldsFragment;
}>({});

export const PrivateCompanyOutlet = () => {
  const { sessionToken: loggedIn, configured } = useAuthContext();

  const [loadPrivateCompany, { data, loading, error }] = useGetMyCompanyLazyQuery({
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (loggedIn && configured && !data) {
      loadPrivateCompany();
    }
  }, [configured, data, loadPrivateCompany, loggedIn]);

  useSubscribeToCompanyChanged({
    id: data?.getMyCompany?.id,
  });

  if (error) return <ErrorPage error={error} />;
  if (!data || loading) return <LoadingPage />;

  if (data.getMyCompany == null) {
    throw new Error('Current User Company Not Found.');
  }

  return (
    <PrivateCompanyContext.Provider
      value={{
        company: data.getMyCompany,
      }}
    >
      <Outlet />
    </PrivateCompanyContext.Provider>
  );
};

export const usePrivateCompanyContext = () => {
  const context = useContext(PrivateCompanyContext);

  if (context == null) throw new Error('usePublicCompanyContext must be used inside the PublicCompanyContext node.');

  if (context.company == null) throw new Error('Current User Company Not Found.');

  return context.company;
};
